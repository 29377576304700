import { Avatar, Grid, IconButton, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import texts from "../../../constants/texts";
import dateToTimeAgo from "../../../helpers/calculateTimeAgo";
import useBindLinkClick from "../../../hooks/useBindLinkClick";
import { DeleteIcon, EditIcon } from "../../../icons";
import { useAppContext } from "../../../store";
import colors from "../../../theme/colors";
import { CommentCreateParams, CommentListItem } from "../../../types/Comments";
import { FeedCategoryEnum } from "../../../types/Feed";
import { ParsedLinksText } from "../../ParsedLinksText";

interface Props {
  data: CommentListItem;
  handleOpenCreateCommentDrawer(v: CommentListItem | undefined): void;
  handleOpenEditCommentDrawer(v: CommentListItem | undefined): void;
  handleOpenDeleteDialog(): void;
}

const useStyles = makeStyles({
  input: {
    fontSize: "15px",
    "& input.Mui-disabled": {
      textFillColor: "#fff",
    },
  },
});

const CommentCard: React.FC<Props> = (props: Props) => {
  const {
    data,
    handleOpenCreateCommentDrawer,
    handleOpenEditCommentDrawer,
    handleOpenDeleteDialog,
  } = props;
  // todo - check if user is owner and show delete icon for comment

  const classes = useStyles();
  const handleBindLinksClick = useBindLinkClick();

  useEffect(() => {
    handleBindLinksClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    state: { user, category, garden },
  } = useAppContext();

  const [model, setModel] = useState<CommentCreateParams>({
    text: "",
    answerOn: "",
  });
  const showReplyField =
    (category === FeedCategoryEnum.ASK_EXPERT && user.isExpert) ||
    category !== FeedCategoryEnum.ASK_EXPERT;
  const isUserOwner = user.id === garden?.owner._id;

  return (
    <>
      <Grid container>
        <Grid item>
          <Avatar
            sx={{ width: "28px", height: "28px" }}
            src={data.author.picture}
            alt={`${data.author.firstName}${data.author.lastName}`}
          />
        </Grid>
        <Grid
          item
          sx={{
            maxWidth: "255px",
            background: "rgba(39, 60, 78, 0.23)",
            padding: "8px",
            marginLeft: "8px",
            borderRadius: "4px",
          }}
        >
          <Grid container alignItems="center">
            <Grid item>
              <Typography sx={{ color: "#D5E5E2" }} variant="caption">
                {`${
                  data.author.firstName
                    ? `${data.author.firstName} ${data.author.lastName}`
                    : `${data.author.displayName}`
                } • ${dateToTimeAgo(new Date(data.createdAt))} ${
                  texts.localizations.ago
                }`}
              </Typography>
            </Grid>
            {data.author.isExpert && (
              <Grid
                item
                sx={{
                  width: "43px",
                  height: "15px",
                  borderRadius: "4px",
                  background: colors.background,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "4px",
                }}
              >
                <Typography
                  color="secondary"
                  sx={{ fontSize: "10px!important" }}
                >
                  {texts.comments.expert}
                </Typography>
              </Grid>
            )}
            {user.id === data.author._id && (
              <Grid item>
                <IconButton onClick={() => handleOpenEditCommentDrawer(data)}>
                  <EditIcon
                    sx={{ width: "14px", height: "14px", color: "#fff" }}
                  />
                </IconButton>
              </Grid>
            )}
            {isUserOwner && user.id !== data.author._id && (
              <IconButton onClick={handleOpenDeleteDialog}>
                <DeleteIcon
                  sx={{
                    width: "14px",
                    height: "14px",
                    color: colors.secondary,
                  }}
                />
              </IconButton>
            )}
          </Grid>
          <ParsedLinksText text={data.text} />
        </Grid>
      </Grid>
      {!data.answerOn && showReplyField && (
        <Grid
          container
          sx={{
            marginLeft: "20px",
            marginTop: "8px",
            paddingRight: "16px",
          }}
          justifyContent="space-between"
        >
          <Grid item>
            <TextField
              placeholder={`${texts.localizations.reply}...`}
              onClick={() => handleOpenCreateCommentDrawer(data)}
              value={model.text}
              disabled
              className={classes.input}
              onChange={(e) =>
                setModel({
                  text: e.target.value,
                  answerOn: model.answerOn,
                })
              }
              sx={{ background: "none" }}
              InputProps={{
                startAdornment: (
                  <Avatar
                    sx={{
                      width: "28px",
                      height: "28px",
                      marginRight: "9px",
                    }}
                    src={user.picture}
                    alt={`${user.firstName}${user.lastName}`}
                  />
                ),
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CommentCard;
