import { Grid, Typography } from "@mui/material";
import React from "react";

interface Props {
  text: string;
  icon: React.ReactElement;
  handleSelectedGardenChange(): void;
  isSelected: boolean;
}

const SliderItem: React.FC<Props> = (props: Props) => {
  const { text, icon, handleSelectedGardenChange, isSelected } = props;

  return (
    <Grid
      sx={{
        borderBottom: isSelected ? "3px solid #FBDE50" : "none",
        display: "flex!important",
        flexDirection: "column",
      }}
      onClick={handleSelectedGardenChange}
      container
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        item
        sx={{
          width: "49px",
          height: "49px",
          borderRadius: "50%",
          background: "#24735E",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
      </Grid>
      <Grid item sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            fontSize: "12px!important",
            textAlign: "center",
          }}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SliderItem;
