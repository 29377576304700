import { Box, Grid, Typography } from "@mui/material";
import EmojiPicker, { IEmojiData } from "emoji-picker-react";
import React from "react";
import { KeyedMutator } from "swr";
import reactionsApi from "../api/reactionsApi";
import texts from "../constants/texts";
import {
  emitReactionSet,
  EmitReactionSetParams,
} from "../helpers/socketReactions";
import useApiRequest from "../hooks/useApiRequest";
import { useAppContext } from "../store";
import colors from "../theme/colors";
import {
  ReactionsList,
  ReactionsListData,
  ReactListItemToObj,
} from "../types/Reactions";

interface Props {
  postId: string;
  setOpen(v: boolean): void;
  displayedData: ReactListItemToObj;
  myRec: string;
  setMyRec(v: string): void;
  data: ReactionsList;
  mutate: KeyedMutator<ReactionsListData>;
}

const Reactions: React.FC<Props> = (props: Props) => {
  const { postId, setOpen, displayedData, myRec, setMyRec, data, mutate } =
    props;

  const {
    state: { category, garden },
  } = useAppContext();

  const { requestFn } = useApiRequest((data) =>
    reactionsApi.createReaction(postId, data)
  );

  const handleAddReaction = async (
    event: React.MouseEvent,
    emojiObject: IEmojiData
  ) => {
    const { emoji } = emojiObject;
    await requestFn({ args: emojiObject.emoji });
    setOpen(false);
    setMyRec(emoji);

    const reactionUpdate: EmitReactionSetParams = {
      postId,
      reactions: data,
      category,
      garden,
    };

    if (myRec !== emoji && Object.keys(displayedData).includes(myRec)) {
      reactionUpdate.decrement = myRec;
    }
    if (myRec !== emoji) {
      reactionUpdate.increment = emoji;
    }
    emitReactionSet(reactionUpdate);

    // if new reaction === myReaction - do nothing
    if (myRec === emoji) {
      setOpen(false);
      return displayedData;
    }

    await mutate();

    return displayedData;
  };

  return (
    <Box
      sx={{
        height: "75vh",
        background: colors.primary,
        borderRadius: "12px 12px 0px 0px",
      }}
    >
      <Box py={3}>
        <Grid
          container
          justifyContent="flex-start"
          sx={{ position: "relative", padding: "0px 24px 0px 24px" }}
        >
          <Grid
            item
            sx={{
              flex: "0 1 auto",
            }}
          >
            <Typography onClick={() => setOpen(false)}>
              {texts.localizations.cancel}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              flex: "0 1 auto",
              left: "50%",
              transform: "translateX(-50%)",
              position: "absolute",
            }}
          >
            <Typography>{texts.localizations.addReaction}</Typography>
          </Grid>
        </Grid>
      </Box>
      <EmojiPicker
        onEmojiClick={handleAddReaction}
        pickerStyle={{
          width: "100%",
          height: "65vh",
          background: colors.primary,
          border: "none",
          boxShadow: "none",
          padding: "0px 5px 0px 5px",
        }}
        disableAutoFocus
        // remove emoji name panels
        groupNames={{
          smileys_people: "",
          animals_nature: "",
          food_drink: "",
          travel_places: "",
          activities: "",
          objects: "",
          symbols: "",
          flags: "",
          recently_used: "",
        }}
        disableSkinTonePicker
      />
    </Box>
  );
};

export default Reactions;
