import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import basic from "../constants/texts/basic";

interface Props {
  open: boolean;
  dialogTitle: string;
  handleDelete(): void;
  setOpen(v: boolean): void;
  isLoading?: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteDialog: React.FC<Props> = (props: Props) => {
  const { open, dialogTitle, handleDelete, setOpen, isLoading } = props;

  const handleDeleteFn = () => {
    handleDelete();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
    >
      <Card>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="text">
            {basic.disagree}
          </Button>
          <Button disabled={isLoading} onClick={handleDeleteFn} variant="text">
            {basic.agree}
          </Button>
        </DialogActions>
      </Card>
    </Dialog>
  );
};

export default DeleteDialog;
