import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import texts from "../../../constants/texts";
import { ImageUploadIcon } from "../../../icons";
import colors from "../../../theme/colors";
import ImageAsync from "../ImageAsync";

interface Props {
  isError?: boolean;
  disabled?: boolean;
  inputId: string;
  isUploading: boolean;
  link?: string;
  handleFileSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputValue: string;
}

const EventImageUpload: React.FC<Props> = ({
  handleFileSelect,
  inputId,
  inputValue,
  isUploading,
  disabled,
  isError,
  link,
}: Props) => {
  return (
    <>
      <Card
        sx={{
          width: 1,
          height: "162px",
          background: colors.textFieldBackground,
          borderRadius: "8px",
          border: `1px solid ${isError ? "red" : "rgba(235, 234, 234, 0.12)"}`,
          position: "relative",
          "&:after": {
            content: '""',
            position: "absolute",
            background: "rgba(0,0,0,0.5)",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: link ? "block" : "none",
          },
        }}
      >
        {link && link.length > 0 && <ImageAsync src={link} alt="Grow App" />}
        {isUploading ? (
          <CircularProgress
            color="secondary"
            size="25px"
            sx={{
              position: "absolute",
              left: "calc(50% - 15px)",
              top: "calc(50% - 15px)",
            }}
          />
        ) : (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              zIndex: 2,
            }}
          >
            <IconButton component="label" htmlFor={`file-upload-${inputId}`}>
              <ImageUploadIcon />
            </IconButton>
            <Typography
              variant="subtitle1"
              sx={{ color: colors.textSecondaryWhite }}
            >
              {link ? texts.basic.replaceImage : texts.basic.addImage}
            </Typography>
          </Box>
        )}
      </Card>
      <input
        type="file"
        disabled={isUploading || disabled}
        style={{
          width: 0,
          height: 0,
          visibility: "hidden",
          display: "none",
        }}
        onChange={handleFileSelect}
        id={`file-upload-${inputId}`}
        name={`file-upload-${inputId}`}
        value={inputValue}
        accept="image/*"
        multiple
      />
    </>
  );
};

export default EventImageUpload;
