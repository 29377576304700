export default {
  events: "Events",
  event: "Event",
  createNewEvent: "Create New Event",
  learnMore: "Learn More",
  nameAndLocation: "Name & Location",
  eventName: "Event Name*",
  location: "Location*",
  dateAndTime: "Date & Time",
  eventIsWholeDay: "Event is the whole day",
  start: "Start *",
  time: "Time",
  end: "End *",
  moreInfo: "More information",
  hasActionButton: "Has action button",
  textOnButton: "Text on button *",
  linkOfButton: "URL / Link of button *",
  gardens: "Gardens",
  locationsOnMap: "Locations on Map",
  eventVisibility: "Visibility*",
  description: "Description",
};
