import { LanguageEnum } from "../../store/types";
import { getCurrentLanguage } from "../../helpers/localStorage";

const language: LanguageEnum = getCurrentLanguage() as LanguageEnum;

const lang = {
  findGardens: {
    en: "Find Gardens",
    nb: "Finn Hager",
  },
  garden: {
    en: "Garden",
    nb: "Hage",
  },
  postSomething: {
    en: "Post something",
    nb: "Post noe",
  },
  leaveAComment: {
    en: "Leave a comment",
    nb: "Legg til en kommentar",
  },
  comments: {
    en: "Comments",
    nb: "Kommentarer",
  },
  cancel: {
    en: "Cancel",
    nb: "Avbryt",
  },
  createPost: {
    en: "Create Post",
    nb: "Opprett Innlegg",
  },
  editPost: {
    en: "Edit Post",
    nb: "Rediger Innlegg",
  },
  post: {
    en: "Post",
    nb: "Post",
  },
  edit: {
    en: "Edit",
    nb: "Rediger",
  },
  create: {
    en: "Create",
    nb: "Opprett",
  },
  writeSomething: {
    en: "Write something…",
    nb: "Skrive noe…",
  },
  save: {
    en: "Save",
    nb: "Lagre",
  },
  askTheExpert: {
    en: "Ask an expert",
    nb: "Spør en ekspert",
  },
  enterTheGardenName: {
    en: "Enter a Garden name",
    nb: "Skriv inn navn på hagen",
  },
  reply: {
    en: "Reply",
    nb: "Svar",
  },
  ago: {
    en: "ago",
    nb: "siden",
  },
  search: {
    en: "Search",
    nb: "Søk",
  },
  hoursAgo: {
    en: "h",
    nb: "t",
  },
  yearsAgo: {
    en: "years",
    nb: "år",
  },
  monthsAgo: {
    en: "months",
    nb: "måneder",
  },
  areYouSureYouWantDeletePost: {
    en: "Are you sure you want to delete this post?",
    nb: "Er du sikker på at du vil slette dette innlegget?",
  },
  agree: {
    en: "Agree",
    nb: "Enig",
  },
  disagree: {
    en: "Disagree",
    nb: "Uenig",
  },
  visitGardenPage: {
    en: "Visit garden page",
    nb: "Besøk hagesiden",
  },
  navigateToGarden: {
    en: "Navigate to Garden",
    nb: "Naviger til Hagen",
  },
  close: {
    en: "Close",
    nb: "Lukk",
  },
  joinGarden: {
    en: "Join Garden",
    nb: "Bli med i Hage",
  },
  joinedGarden: {
    en: "Joined Garden",
    nb: "Ble med i Hage",
  },
  location: {
    en: "Location",
    nb: "Plassering",
  },
  members: {
    en: "Members",
    nb: "Medlemmer",
  },
  addReaction: {
    en: "Add Reaction",
    nb: "Legg til reaksjon",
  },
  searchEmoji: {
    en: "Search Emoji...",
    nb: "Søk etter Emoji...",
  },
  postWasCreated: {
    en: "Post was created",
    nb: "Innlegget ble opprettet",
  },
  postWasUpdated: {
    en: "Post was updated",
    nb: "Innlegget ble oppdatert",
  },
  done: {
    en: "Done",
    nb: "Ferdig",
  },
  selectLocation: {
    en: "Select location",
    nb: "Velg plassering",
  },
  cardInfoSubscribe: {
    en: "Data imported from Kart Urbant Landbruk i Oslo",
    nb: "Data importert fra Kart Urbant Landbruk i Oslo",
  },
  website: {
    en: "Website",
    nb: "Nettside",
  },
};

export default {
  findGardens: lang.findGardens[language],
  postSomething: lang.postSomething[language],
  leaveAComment: lang.leaveAComment[language],
  comments: lang.comments[language],
  cancel: lang.cancel[language],
  createPost: lang.createPost[language],
  editPost: lang.editPost[language],
  post: lang.post[language],
  edit: lang.edit[language],
  writeSomething: lang.writeSomething[language],
  save: lang.save[language],
  askTheExpert: lang.askTheExpert[language],
  enterTheGardenName: lang.enterTheGardenName[language],
  create: lang.create[language],
  garden: lang.garden[language],
  reply: lang.reply[language],
  ago: lang.ago[language],
  search: lang.search[language],
  hoursAgo: lang.hoursAgo[language],
  yearsAgo: lang.yearsAgo[language],
  monthsAgo: lang.monthsAgo[language],
  areYouSureYouWantDeletePost: lang.areYouSureYouWantDeletePost[language],
  agree: lang.agree[language],
  disagree: lang.disagree[language],
  visitGardenPage: lang.visitGardenPage[language],
  navigateToGarden: lang.navigateToGarden[language],
  close: lang.close[language],
  joinGarden: lang.joinGarden[language],
  joinedGarden: lang.joinedGarden[language],
  location: lang.location[language],
  members: lang.members[language],
  addReaction: lang.addReaction[language],
  searchEmoji: lang.searchEmoji[language],
  postWasCreated: lang.postWasCreated[language],
  postWasUpdated: lang.postWasUpdated[language],
  done: lang.done[language],
  selectLocation: lang.selectLocation[language],
  cardInfoSubscribe: lang.cardInfoSubscribe[language],
  website: lang.website[language],
};
