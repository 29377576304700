import texts from "../constants/texts";

const dateToTimeAgo = (date: Date): string => {
  // @ts-ignore
  const seconds = Math.floor((new Date() - date) / 1000);

  // eslint-disable-next-line
  var interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)} ${texts.localizations.yearsAgo}`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} ${texts.localizations.monthsAgo}`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)}d`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)} ${texts.localizations.hoursAgo}`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)}m`;
  }
  return `${Math.floor(seconds)}s`;
};

export default dateToTimeAgo;
