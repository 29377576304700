import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const CirlceCloseIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity="0.23" cx="16" cy="16" r="16" fill="#585151" />
    <path
      d="M21.9711 11.7348L17.706 15.9999L21.9711 20.265L20.2651 21.971L16 17.7059L11.7349 21.971L10.0289 20.265L14.294 15.9999L10.0289 11.7348L11.7349 10.0288L16 14.2938L20.2651 10.0288L21.9711 11.7348Z"
      fill="white"
    />
  </SvgIcon>
);

export default CirlceCloseIcon;
