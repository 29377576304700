import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSWR from "swr";
import colors from "../../theme/colors";
import CreatePost from "../../components/CreatePost";
import { FindGardensIcon, GardenInfoIcon } from "../../icons";
import { appLinks } from "../../routes/routes";
import "./styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import feedApi from "../../api/feedApi";
import { FeedCategoryEnum, FeedListData, FeedListItem } from "../../types/Feed";
import { useAppContext } from "../../store";
import { setCategory, setGarden } from "../../store/actions";
import socket, {
  addMessageHandler,
  removeMessageHandler,
} from "../../helpers/socket";
import { FromServerPostCreatedEvent } from "../../types/ServerEvents";
import GardensSlider from "./components/GardensSlider";
import PostsList from "./components/PostsList";
import { GardensListItem } from "../../types/Gardens";
import texts from "../../constants/texts";
import GardenDetails from "../../components/GardenDetails";
import gardensApi from "../../api/gardensApi";
import { UrlParamsEnum } from "../../types/common";
import { MainDrawer } from "../../components/MainDrawer";
import useSearchParamsHandler from "../../hooks/useSearchParamsHandler";
import {
  deleteSelectedGardenFromLocalStorage,
  setCategoryToLocalStorage,
  setSelectedGardenToLocalStorage,
} from "../../helpers/localStorage";
import PullToRefreshWrapper from "../../components/PullToRefreshWrapper";

const Feed: React.FC = () => {
  const navigate = useNavigate();
  const {
    state: { garden, category },
    dispatch,
  } = useAppContext();

  const [selectedGarden, setSelectedGarden] = useState<string | undefined>(
    garden?.id
  );
  const [lastItemCreatedDate, setLastItemCreatedDate] = useState<
    string | undefined
  >("");

  const [displayedData, setDisplayedData] = useState<FeedListData>({});
  const [data, setData] = useState<FeedListData>({});
  const [isCreatePostDrawerOpen, setIsCreatePostDrawerOpen] = useState(false);
  const [isEditPostDrawerOpen, setIsEditPostDrawerOpen] = useState(false);
  const [editPostItem, setEditPostItem] = useState<FeedListItem | undefined>();
  const [isShowNewPostsButton, setIsShowNewPostsButton] = useState(false);
  const [isOpenGardenInfoDrawer, setIsOpenGardenInfoDrawer] = useState<
    boolean | null
  >(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpenMainDrawer, setIsOpenMainDrawer] = useState<boolean>(false);
  const [isRevalidate, setIsRevalidate] = useState(false);
  const [isLoadingPosts, setIsLoadingPosts] = useState(false);
  const { searchParamsRemoverFn, searchParamsSetterFn } =
    useSearchParamsHandler();

  const {
    data: subscribedGardensData,
    error: subscribedGardensError,
    mutate,
  } = useSWR(["/gardens/subscribed"], () =>
    gardensApi.getGardensSubscribedList()
  );

  const handleSetOrRemoveGardenIdParam = useCallback(
    (gardenId: string | undefined) => {
      if (gardenId) {
        searchParamsSetterFn({ [UrlParamsEnum.FOCUS]: gardenId });
        return;
      }

      searchParamsRemoverFn([UrlParamsEnum.FOCUS]);
    },
    [searchParamsRemoverFn, searchParamsSetterFn]
  );

  const handleSetFullScreenParams = useCallback(() => {
    searchParamsSetterFn({ [UrlParamsEnum.FULL_SCREEN]: "true" });
  }, [searchParamsSetterFn]);

  const handleOpenGardenDetailsDrawer = useCallback(() => {
    handleSetFullScreenParams();
    setIsOpenGardenInfoDrawer(true);
  }, [handleSetFullScreenParams]);

  const handleOpenHomeDrawer = useCallback(() => {
    handleSetFullScreenParams();
    setIsOpenMainDrawer(true);
  }, [handleSetFullScreenParams]);

  const handleSelectedGardenChange = useCallback(
    (
      gardenData: GardensListItem | undefined,
      updatedCategory: FeedCategoryEnum
    ) => {
      if (gardenData?.id !== garden?.id || updatedCategory !== category) {
        socket.emit({ event: "ROOM::LEAVE", payload: garden?.id || category });
        handleSetOrRemoveGardenIdParam(gardenData?.id);

        dispatch(setCategory(updatedCategory));
        setCategoryToLocalStorage(updatedCategory);
        setLastItemCreatedDate(undefined);

        dispatch(setGarden(gardenData));
        if (gardenData) {
          setSelectedGardenToLocalStorage(gardenData as GardensListItem);
        } else {
          deleteSelectedGardenFromLocalStorage();
        }
        setSelectedGarden(gardenData?.id);

        setDisplayedData({});
      }
    },
    [category, dispatch, garden?.id, handleSetOrRemoveGardenIdParam]
  );

  useEffect(() => {
    const updatedGardenId = searchParams.get(UrlParamsEnum.UPDATE_GARDEN_ID);
    if (updatedGardenId && subscribedGardensData) {
      const updatedGarden = subscribedGardensData.filter(
        (garden) => garden.id === updatedGardenId
      )[0];
      dispatch(setGarden(updatedGarden));
      handleOpenGardenDetailsDrawer();
      searchParams.delete(UrlParamsEnum.UPDATE_GARDEN_ID);
      setSearchParams(searchParams);
      handleSelectedGardenChange(updatedGarden, FeedCategoryEnum.GARDEN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams,
    subscribedGardensData,
    dispatch,
    handleOpenGardenDetailsDrawer,
    setSearchParams,
  ]);

  const handleNavigateToCreatePost = () => {
    setLastItemCreatedDate(undefined);
    setDisplayedData({});
    setIsCreatePostDrawerOpen(true);
    handleSetFullScreenParams();
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingPosts(true);
      const response = await feedApi.getListOfPosts(
        garden?.id,
        category,
        lastItemCreatedDate
      );
      if (lastItemCreatedDate === undefined) {
        setDisplayedData((prev: FeedListData) => ({ ...response, ...prev }));
      } else {
        setDisplayedData((prev: FeedListData) => ({ ...prev, ...response }));
      }
      setData(response);
      setIsLoadingPosts(false);
    };
    fetchData();
  }, [
    category,
    garden,
    lastItemCreatedDate,
    isCreatePostDrawerOpen,
    isEditPostDrawerOpen,
    isRevalidate,
  ]);

  useEffect(() => {
    if (garden && subscribedGardensData) {
      const updatedGarden = subscribedGardensData.filter(
        (subscribedGarden) => subscribedGarden.id === garden.id
      )[0];
      dispatch(setGarden(updatedGarden));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedGardensData, dispatch]);

  useEffect(() => {
    socket.emit({ event: "ROOM::JOIN", payload: garden?.id || category });
  }, [garden, category]);

  useEffect(() => {
    addMessageHandler<FromServerPostCreatedEvent>("POST::CREATED", () =>
      setIsShowNewPostsButton(true)
    );
    return () => removeMessageHandler("POST::CREATED");
  }, []);

  const handleRedirectTo = (link: string) => {
    socket.emit({ event: "ROOM::LEAVE", payload: garden?.id || category });
    navigate(link);
  };

  const handleUpdatePosts = () => {
    setLastItemCreatedDate(undefined);
    setIsShowNewPostsButton(false);
  };

  const handleToggleRevalidateData = () => {
    setIsRevalidate((prev) => !prev);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <PullToRefreshWrapper
        handleToggleRevalidateData={handleToggleRevalidateData}
        isLoading={isLoadingPosts}
      >
        <Grid
          sx={{
            position: "relative",
            paddingTop: "64px",
            "&: after": {
              content: "''",
              position: "absolute",
              background: "rgba(0, 0, 0, 0.5)",
              width: isOpenGardenInfoDrawer ? "100%" : 0,
              height: "100vh",
              top: 0,
              left: 0,
              opacity: isOpenGardenInfoDrawer ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
            },
          }}
        >
          <div>
            <Grid
              sx={{
                position: "absolute",
                left: "50%",
                transform: isShowNewPostsButton
                  ? "translate(-50%, 25px)"
                  : "translate(-50%, -1000px)",
                transition: "all 0.5s ease-in",
              }}
            >
              <Button variant="contained" onClick={handleUpdatePosts}>
                {texts.feed.showNewPosts}
              </Button>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              sx={{
                paddingLeft: "16px",
                paddingRight: "24px",
              }}
            >
              <Grid item>
                <Typography
                  onClick={() => handleRedirectTo(appLinks.findGardens.asLink)}
                  sx={{
                    width: "148px",
                    height: "38px",
                    background: colors.textFieldBackground,
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FindGardensIcon />
                  <Typography
                    sx={{
                      paddingLeft: "10px",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                    variant="caption"
                  >
                    {texts.localizations.findGardens}
                  </Typography>
                </Typography>
              </Grid>

              {category !== "askExpert" && (
                <Grid item>
                  <Grid
                    onClick={
                      garden
                        ? handleOpenGardenDetailsDrawer
                        : handleOpenHomeDrawer
                    }
                    sx={{
                      width: "38px",
                      height: "38px",
                      background: colors.textFieldBackground,
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <GardenInfoIcon sx={{ with: "18px", height: "18px" }} />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <GardensSlider
              handleSelectedGardenChange={handleSelectedGardenChange}
              selectedGarden={selectedGarden}
              subscribedGardensError={subscribedGardensError}
              subscribedGardensData={subscribedGardensData}
              handleToggleRevalidateData={handleToggleRevalidateData}
            />
            <CreatePost
              handleNavigateToCreatePost={handleNavigateToCreatePost}
              open={isCreatePostDrawerOpen}
              setOpen={setIsCreatePostDrawerOpen}
              setDisplayedData={setDisplayedData}
            />
            <PostsList
              data={data}
              displayedData={displayedData}
              handleRedirectTo={handleRedirectTo}
              setLastItemCreatedDate={setLastItemCreatedDate}
              setEditPostItem={setEditPostItem}
              setIsOpenPostEditDrawer={setIsEditPostDrawerOpen}
              isEditPostDrawerOpen={isEditPostDrawerOpen}
              editPostItem={editPostItem}
              setDisplayedData={setDisplayedData}
            />
          </div>
          {garden && (
            <GardenDetails
              data={garden}
              open={isOpenGardenInfoDrawer}
              setOpen={setIsOpenGardenInfoDrawer}
              mutate={mutate}
              handleSelectedGardenChange={handleSelectedGardenChange}
            />
          )}
          <MainDrawer open={isOpenMainDrawer} setOpen={setIsOpenMainDrawer} />
        </Grid>
      </PullToRefreshWrapper>
    </motion.div>
  );
};

export default Feed;
