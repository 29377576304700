import { CircularProgress, IconButton } from "@mui/material";
import React from "react";
import { PickImageIcon } from "../../../icons";

interface Props {
  isError?: boolean;
  disabled?: boolean;
  inputId: string;
  isUploading: boolean;
  link?: string;
  handleFileSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputValue: string;
}

const ImageInputForPost: React.FC<Props> = (props: Props) => {
  const {
    isError,
    disabled,
    inputId,
    isUploading,
    // eslint-disable-next-line
    link,
    handleFileSelect,
    inputValue,
  } = props;

  return (
    <>
      {isUploading ? (
        <CircularProgress color="secondary" size="25px" />
      ) : (
        <IconButton component="label" htmlFor={`file-upload-${inputId}`}>
          <PickImageIcon color={isError ? "error" : "secondary"} />
        </IconButton>
      )}
      <input
        type="file"
        disabled={isUploading || disabled}
        style={{
          width: 0,
          height: 0,
          visibility: "hidden",
          display: "none",
        }}
        onChange={handleFileSelect}
        id={`file-upload-${inputId}`}
        name={`file-upload-${inputId}`}
        value={inputValue}
        accept="image/*"
        multiple
      />
    </>
  );
};

export default ImageInputForPost;
