import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Drawer,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import colors from "../theme/colors";
import useSearchParamsHandler from "../hooks/useSearchParamsHandler";
import { appLinks } from "../routes/routes";
import { UrlParamsEnum } from "../types/common";
import texts from "../constants/texts";
import Globus from "../icons/Globus";

interface Props {
  open: boolean;
  setOpen(v: boolean): void;
}

const ContentData = [
  {
    icon: <Globus />,
    title: texts.informationModal.subsidy,
    link: "www.oslo.kommune.no/tilskudd-legater-og-stipend/tilskudd-til-urbant-landbruk/",
  },
  {
    icon: <Globus />,
    title: texts.informationModal.burgeoning,
    link: "www.oslo.kommune.no/natur-kultur-og-fritid/urbant-landbruk/om-spirende-oslo",
  },
  {
    icon: <Globus />,
    title: texts.informationModal.grantPortal,
    link: "www.tilskuddsportalen.no",
  },
  {
    icon: <Globus />,
    title: texts.informationModal.volunteering,
    link: "www.frivillighetnorge.no",
  },
  {
    icon: <Globus />,
    title: texts.informationModal.authority,
    link: "lottstift.no/for-frivilligheten/tildelingar-til-frivilligheita/",
  },
  {
    icon: <Globus />,
    title: texts.informationModal.organizations,
    link: "press.no/organisasjonersomfunker/sok-stotte",
  },
  {
    icon: <Globus />,
    title: texts.informationModal.foundation,
    link: "www.gjensidigestiftelsen.no/om-gjensidigestiftelsen/utdelinger/utlysninger",
  },
];

const { EXTERNAL_URL, FULL_SCREEN } = UrlParamsEnum;

export const MainDrawer: React.FC<Props> = ({ open, setOpen }): JSX.Element => {
  const { pathname } = useLocation();

  const { searchParamsRemoverFn, searchParamsSetterFn } =
    useSearchParamsHandler();

  const handleClearSearchParams = () => {
    searchParamsRemoverFn([EXTERNAL_URL, FULL_SCREEN]);
  };

  const handleSetSearchParams = (param: UrlParamsEnum, url: string) => {
    handleClearSearchParams();
    searchParamsSetterFn({ [FULL_SCREEN]: "true", [param]: url });
  };

  const handleCloseGardenDetails = () => {
    if (pathname === appLinks.index.link) {
      handleClearSearchParams();
    }
    setOpen(false);
    setTimeout(() => {
      setOpen(false);
    }, 300);
  };

  return (
    <Drawer
      open={open}
      anchor="bottom"
      PaperProps={{
        style: {
          transition: "height .3s ease-in-out",
          borderRadius: "12px 12px 0px 0px",
          height: "75vh",
          paddingBottom: "35px",
        },
      }}
    >
      <Card
        sx={{
          overflowY: "scroll",
          objectFit: "contain",
          position: "relative",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          boxShadow: "none",
          zIndex: 1,
          "&:after": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
          },
        }}
      >
        <CardContent
          sx={{
            zIndex: 2,
            position: "relative",
            padding: "45px 24px 0px 24px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container sx={{ width: "100%" }}>
            <Grid
              container
              flexWrap="nowrap"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item onClick={() => handleCloseGardenDetails()}>
                <Typography variant="subtitle1">
                  {texts.localizations.close}
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Typography variant="subtitle1">
                  {texts.feed.osloArea}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Typography variant="subtitle2" mt="26px">
              {texts.informationModal.description}
            </Typography>
          </Grid>
          <Grid container direction="column" sx={{ marginTop: "16px" }}>
            {ContentData.map(({ icon, title, link }, index) => (
              <Grid
                item
                // eslint-disable-next-line react/no-array-index-key
                key={`${title}${index}`}
                onClick={() =>
                  handleSetSearchParams(UrlParamsEnum.EXTERNAL_URL, link)
                }
                sx={{
                  borderTop: "1px solid rgba(81,163,141)",
                  height: "57px",
                  display: "flex",
                  gap: "16px",
                  alignItems: "center",
                }}
              >
                {icon}
                <Typography variant="subtitle2">{title}</Typography>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ mt: 5, height: "50px", transition: "opacity .5s ease" }}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                setOpen(false);
                handleClearSearchParams();
              }}
              sx={{
                background: colors.headerBg,
                color: colors.white,
                boxShadow: "none",
              }}
            >
              {texts.localizations.close}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Drawer>
  );
};
