import React, { useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Fade,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { KeyedMutator } from "swr";
import { useSearchParams } from "react-router-dom";
import texts from "../../../constants/texts";
import { CirlceCloseIcon, InfoIcon, NavigateIcon } from "../../../icons";
import colors from "../../../theme/colors";
import { GardensListData, GardensListItem } from "../../../types/Gardens";
import GardenDetails from "../../../components/GardenDetails";
import { UrlParamsEnum } from "../../../types/common";
import { appLinks } from "../../../routes/routes";
import importedGardenIcon from "../../../assets/ImportedGarden.svg";
import MediaIcons from "./MediaIcons";

interface Props {
  open: boolean;
  setOpen(v: boolean): void;
  data: GardensListItem | undefined;
  setGardenInfo(v: GardensListItem | undefined): void;
  mutate: KeyedMutator<GardensListData>;
}

const GardenInfoCard: React.FC<Props> = (props: Props) => {
  const { open, setOpen, data, setGardenInfo, mutate } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const [isOpenGardenDetailsDrawer, setIsOpenGardenDetailsDrawer] = useState<
    boolean | null
  >(null);

  const handleSetExternalUrl = (url: string) => {
    searchParams.set(UrlParamsEnum.EXTERNAL_URL, url);
    setSearchParams(searchParams);
  };

  const handleCloseInfoCard = () => {
    setOpen(false);
    setGardenInfo(undefined);
    setIsOpenGardenDetailsDrawer(null);
  };

  return (
    <div
      style={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Fade in={open} timeout={500}>
        <Card
          sx={() => ({
            width: "100%",
            height: "321px",
            position: "absolute",
            bottom: 0,
            borderRadius: "11px 11px 0px 0px",
            zIndex: 222,
          })}
        >
          {data && (
            <>
              <CardHeader
                sx={{
                  "& .MuiCardHeader-action": {
                    alignSelf: data.imported ? "center" : "flex-start",
                  },
                }}
                avatar={
                  <Avatar
                    src={data.imported ? importedGardenIcon : data.ownerPicture}
                    sx={{ width: "48px", height: "48px" }}
                  />
                }
                action={
                  <IconButton onClick={handleCloseInfoCard}>
                    <CirlceCloseIcon sx={{ width: "32px", height: "32px" }} />
                  </IconButton>
                }
                title={data.name}
                subheader={
                  <>
                    {data.location}
                    <br />
                    {data.category}
                  </>
                }
              />
              <CardContent sx={{ marginTop: "-20px" }}>
                {data.subscribers && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    {data.subscribers.slice(0, 3).map((subscriber) => (
                      <Avatar
                        key={subscriber._id}
                        src={subscriber.picture}
                        alt={`${subscriber.displayName}`}
                        sx={{
                          width: "34px",
                          height: "34px",
                          "@media(max-width: 768px)": {
                            width: "24px",
                            height: "24px",
                          },
                        }}
                      />
                    ))}
                    {data.subscribers.length > 3 && (
                      <Grid
                        sx={{
                          width: "34px",
                          height: "34px",
                          background: colors.textFieldBackground,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          "@media(max-width: 768px)": {
                            width: "24px",
                            height: "24px",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "16px",
                            "@media(max-width: 768px)": {
                              fontSize: "9px",
                            },
                          }}
                        >
                          {data.subscribers.length - 3}
                        </Typography>
                      </Grid>
                    )}
                  </Stack>
                )}
                <Grid
                  container
                  direction="column"
                  sx={{ marginTop: data.imported ? "0px" : "16px" }}
                >
                  {!data.imported && (
                    <Grid
                      item
                      sx={{
                        borderTop: "1px solid rgba(81,163,141)",
                        height: "57px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        onClick={() => setIsOpenGardenDetailsDrawer(true)}
                      >
                        <Grid item sx={{ marginTop: "5px" }}>
                          <InfoIcon />
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">
                            {texts.localizations.visitGardenPage}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    onClick={() =>
                      handleSetExternalUrl(
                        appLinks.googleMap.link(
                          data.locationCoordinates.lat,
                          data.locationCoordinates.lng
                        )
                      )
                    }
                    item
                    sx={{
                      width: "100%",
                      borderTop: "1px solid rgba(81,163,141)",
                      height: "57px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item sx={{ marginTop: "5px" }}>
                        <NavigateIcon sx={{ width: "24px", height: "24px" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="caption">
                          {texts.localizations.navigateToGarden}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: "5px" }}>
                    <MediaIcons
                      facebookLink={data.fb_url || data.facebook}
                      instagramLink={data.instagram}
                      twitterLink={data.twitter}
                      webLink={data.url || data.gardenUrl}
                    />
                  </Box>
                </Grid>
              </CardContent>
            </>
          )}
        </Card>
      </Fade>
      {data && (
        <GardenDetails
          open={isOpenGardenDetailsDrawer}
          setOpen={setIsOpenGardenDetailsDrawer}
          setGardenInfo={setGardenInfo}
          data={data}
          mutate={mutate}
          setIsOpenGardenInfoCard={setOpen}
        />
      )}
    </div>
  );
};

export default GardenInfoCard;
