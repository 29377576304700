export default {
  cancel: "Cancel",
  close: "Close",
  noResults: "No Results",
  cantLoadData: "Can`t load data",
  post: "Post",
  save: "Save",
  agree: "Agree",
  disagree: "Disagree",
  edit: "Edit",
  addImage: "Add image",
  replaceImage: "Replace Image",
  delete: "Delete",
};
