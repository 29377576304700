import React from "react";
import { Box, Button, Drawer, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AutosizeInput from "../../Forms/AutosizeInput";
import texts from "../../../constants/texts";
import { useAppContext } from "../../../store";
import colors from "../../../theme/colors";
import { CommentCreateParams, CommentListItem } from "../../../types/Comments";
import CommentBottomMenu from "./CommentBottomMenu";
import { getTrimmedText } from "../../../helpers/common";

interface Props {
  editItem: CommentListItem | undefined;
  open: boolean;
  setOpen(v: boolean): void;
  onSubmit(): Promise<void>;
  setEditItem(v: CommentListItem | undefined): void;
  model: CommentCreateParams;
  setModel(v: CommentCreateParams): void;
  isLoading?: boolean;
  isDeletingComment?: boolean;
  isUpdatingComment?: boolean;
  handleOpenDeleteDialog(): void;
}

const useStyles = makeStyles({
  padding: {
    paddingLeft: "24px",
    paddingRight: "24px",
    "@media(max-width: 350px)": {
      paddingLeft: "7px",
      paddingRight: "7px",
    },
  },
});

const EditCommentDrawer: React.FC<Props> = (props: Props) => {
  const {
    editItem,
    open,
    setOpen,
    onSubmit,
    setEditItem,
    model,
    setModel,
    isLoading,
    isDeletingComment,
    isUpdatingComment,
    handleOpenDeleteDialog,
  } = props;
  const classes = useStyles();

  const {
    state: { garden },
  } = useAppContext();

  const handleCloseCommentEditDrawer = () => {
    setEditItem(undefined);
    setModel({
      text: "",
      answerOn: "",
    });
    setOpen(false);
  };

  const handleSubmit = () => {
    onSubmit();
    setOpen(false);
    setModel({
      text: "",
      answerOn: "",
    });
  };

  return (
    <Drawer
      open={open}
      anchor="bottom"
      variant="temporary"
      sx={{
        "& .MuiDrawer-paper": {
          background: colors.primary,
        },
      }}
    >
      <Box
        sx={{
          minHeight: "100vh",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          className={classes.padding}
          alignItems="center"
          sx={{ height: "104px", pt: "40px", marginBottom: "50px" }}
        >
          <Grid item>
            <Typography
              variant="subtitle1"
              onClick={handleCloseCommentEditDrawer}
            >
              {texts.localizations.cancel}
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="subtitle1" fontWeight={500}>
                  {editItem
                    ? texts.localizations.edit
                    : texts.localizations.create}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <Typography variant="body1">
                    {garden ? getTrimmedText(garden.name, 25) : "Oslo"}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              disabled={isLoading || isUpdatingComment}
              onClick={handleSubmit}
              variant="text"
            >
              <Typography
                variant="subtitle1"
                fontWeight={500}
                color="secondary"
              >
                {texts.localizations.save}
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid className={classes.padding} sx={{ marginBottom: "52px" }}>
          <AutosizeInput
            onChange={(e) => setModel({ text: e, answerOn: model.answerOn })}
            value={model.text}
          />
        </Grid>
      </Box>
      {editItem && (
        <CommentBottomMenu
          isDeletingComment={isDeletingComment}
          handleOpenDeleteDialog={() => handleOpenDeleteDialog()}
        />
      )}
    </Drawer>
  );
};

export default EditCommentDrawer;
