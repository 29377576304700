export default {
  primary: "#2D9075",
  secondary: "#FDE466",
  white: "#ffffff",
  black: "#000000",
  textSecondaryWhite: "rgba(255, 255, 255, 0.8)",
  background: "#287262",
  textFieldBackground: "#2C7D6C",
  buttonTextColor: "#907B16",
  inputPlaceholderColor: "rgba(255, 255, 255, 0.58)",
  headerBg: "#1D7B62",
  eventCardBorder: "rgba(0, 0, 0, 0.08)",
  checkedSwitchBg: "#FBDE50",
  textFieldBorder: "rgba(235, 234, 234, 0.12)",
};
