import React, { useEffect, useState } from "react";
import { Card, Drawer } from "@mui/material";
import useSWR from "swr";
import { LongPressDetectEvents, useLongPress } from "use-long-press";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { UserDataForPostDetails } from "../../types/Users";
import { FeedListItem } from "../../types/Feed";
import { useAppContext } from "../../store";
import Reactions from "../Reactions";
import { appLinks } from "../../routes/routes";
import reactionsApi from "../../api/reactionsApi";
import {
  ReactionsListData,
  ReactionsListItem,
  ReactListItemToObj,
} from "../../types/Reactions";
import useApiRequest from "../../hooks/useApiRequest";
import { emitReactionSet } from "../../helpers/socketReactions";
import { addMessageHandler, removeMessageHandler } from "../../helpers/socket";
import { FromServerReactionUpdatedEvent } from "../../types/ServerEvents";
import CardInfo from "./CardInfo";
import ReactionsList from "./ReactionsList";

interface Props {
  userData: UserDataForPostDetails;
  location?: string;
  setEditPostItem?(v: FeedListItem): void;
  setIsOpenPostEditDrawer?(v: boolean): void;
  postData: FeedListItem;
  setLastItemCreatedDate?(v: string | undefined): void;
  handleRedirectTo?(v: string): void;
  setReactions?(v: ReactionsListData | undefined): void;
  reactions?: ReactionsListData;
}

const useStyles = makeStyles({
  root: {
    borderRadius: "12px 12px 0px 0px",
  },
});

const PostCard: React.FC<Props> = (props: Props) => {
  const {
    userData,
    location,
    setEditPostItem,
    setIsOpenPostEditDrawer,
    postData,
    setLastItemCreatedDate,
    handleRedirectTo,
    setReactions,
    reactions,
  } = props;
  const classes = useStyles();
  const { id } = useParams();

  const handleConvertArrayToObj = (array: ReactionsListItem[]) => {
    const result = array.reduce((accumulator, value) => {
      return { ...accumulator, [value.reaction]: value.count };
    }, {});
    return result;
  };

  const {
    state: { user, category, garden },
  } = useAppContext();

  const { data, error, mutate } = useSWR([`/reactions/${postData._id}`], () =>
    reactionsApi.getListOfReactions(postData._id)
  );
  const { requestFn, isLoading } = useApiRequest((data) =>
    reactionsApi.createReaction(postData._id, data)
  );
  const { requestFn: removeReactionFn, isLoading: isRemovingReaction } =
    useApiRequest(() => reactionsApi.removeReaction(postData._id));

  const addOrRemoveReactionHandler = (reaction: string) => {
    if (!data) {
      return;
    }
    if (myRec === reaction) {
      handleRemoveReaction();
    } else {
      handleAddReaction(reaction);
    }
  };

  const handleRemoveReaction = async () => {
    await removeReactionFn({});

    emitReactionSet({
      category,
      garden,
      postId: postData._id,
      reactions: data?.reactions || [],
      decrement: myRec,
    });

    await mutate();
  };

  const handleAddReaction = async (reaction: string) => {
    await requestFn({ args: reaction });

    emitReactionSet({
      category,
      garden,
      postId: postData._id,
      reactions: data?.reactions || [],
      increment: reaction,
      decrement: myRec,
    });

    await mutate();

    setMyRec(reaction);
  };

  const callback = React.useCallback(() => {
    if (
      setEditPostItem &&
      setIsOpenPostEditDrawer &&
      user.id === postData.author._id
    ) {
      setEditPostItem(postData);
      setIsOpenPostEditDrawer(true);
      if (setLastItemCreatedDate) {
        setLastItemCreatedDate(undefined);
      }
    }
  }, [
    postData,
    setEditPostItem,
    setIsOpenPostEditDrawer,
    setLastItemCreatedDate,
    user.id,
  ]);

  const bind = useLongPress(callback, {
    threshold: 600,
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH,
  });

  const [isOpenReactionsDrawer, setIsOpenReactionsDrawer] = useState(false);
  const [displayedData, setDisplayedData] = useState<ReactListItemToObj>({});
  const [myRec, setMyRec] = useState<string>("");

  const navigateToPostDetails = () => {
    if (handleRedirectTo) {
      handleRedirectTo(appLinks.postDetails.asLink(postData._id));
    }
  };

  useEffect(() => {
    if (data) {
      if (id) {
        if (data.reactions.length) {
          const convertedToObjectData = data.reactions.reduce(
            (accumulator, value) => {
              return { ...accumulator, [value.reaction]: value.count };
            },
            {}
          );
          setDisplayedData(convertedToObjectData);
        }
      }
      if (!id) {
        if (reactions && reactions.reactions) {
          const dataOrReactions = !reactions
            ? data.reactions
            : reactions.reactions;

          const convertedToObjectData = dataOrReactions.reduce(
            (accumulator, value) => {
              return { ...accumulator, [value.reaction]: value.count };
            },
            {}
          );
          setDisplayedData(convertedToObjectData);
        }
      }
      setMyRec(data.myReaction ? data.myReaction.reaction : "");
    }
  }, [data, id, reactions]);

  useEffect(() => {
    if (id) {
      addMessageHandler<FromServerReactionUpdatedEvent>(
        "REACTION::UPDATED",
        (payload) => {
          const { reactions } = payload;

          const convertedArray: ReactListItemToObj =
            handleConvertArrayToObj(reactions);

          setDisplayedData(convertedArray);
        }
      );
    }

    return () => {
      removeMessageHandler("REACTION::UPDATED");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedData, myRec]);

  useEffect(() => {
    if (setReactions) {
      setReactions(reactions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactions]);

  useEffect(() => {
    if (data && setReactions) {
      setReactions(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Card sx={{ width: "100%", boxShadow: "none" }}>
        <CardInfo
          bind={bind}
          navigateToPostDetails={navigateToPostDetails}
          postData={postData}
          userData={userData}
          location={location}
        />
        {data && (
          <ReactionsList
            error={error}
            addOrRemoveReactionHandler={addOrRemoveReactionHandler}
            data={data}
            displayedData={displayedData}
            myRec={myRec}
            setIsOpenReactionsDrawer={setIsOpenReactionsDrawer}
            isLoading={isLoading}
            isRemovingReaction={isRemovingReaction}
            postData={postData}
          />
        )}
      </Card>
      {data && (
        <Drawer
          open={isOpenReactionsDrawer}
          anchor="bottom"
          classes={{
            paper: classes.root,
          }}
        >
          <Reactions
            postId={postData._id}
            setOpen={setIsOpenReactionsDrawer}
            displayedData={displayedData}
            myRec={myRec}
            setMyRec={setMyRec}
            data={data.reactions}
            mutate={mutate}
          />
        </Drawer>
      )}
    </>
  );
};

export default PostCard;
