import { UserData, UserDataResponse } from "../types/Users";
import { apiClient } from "./apiClient";
import ENDPOINTS from "./endpoints";

const endpoint = ENDPOINTS.USERS;

const getUserById = (id: string): UserDataResponse =>
  apiClient.get<UserData>(`${endpoint}/${id}`).then((res) => res.data);

const getCurrentUser = (): UserDataResponse =>
  apiClient.get<UserData>(`${endpoint}/me`).then((res) => res.data);

export default {
  getUserById,
  getCurrentUser,
};
