import React from "react";
import { Grid } from "@mui/material";
import { CommentListItem, CommentsListData } from "../../../types/Comments";
import CommentCard from "./CommentCard";

interface Props {
  displayedData: CommentsListData;
  handleOpenCreateCommentDrawer(comment: CommentListItem): void;
  handleOpenDeleteDialog(comment: CommentListItem): void;
  handleOpenEditCommentDrawer(comment: CommentListItem): void;
}

const CommentsList: React.FC<Props> = ({
  displayedData,
  handleOpenCreateCommentDrawer,
  handleOpenDeleteDialog,
  handleOpenEditCommentDrawer,
}: Props) => {
  return (
    <>
      {Object.keys(displayedData).map((comment) => (
        <Grid item key={displayedData[comment]._id}>
          <CommentCard
            data={displayedData[comment]}
            handleOpenCreateCommentDrawer={handleOpenCreateCommentDrawer}
            handleOpenEditCommentDrawer={handleOpenEditCommentDrawer}
            handleOpenDeleteDialog={() =>
              handleOpenDeleteDialog(displayedData[comment])
            }
          />
          {displayedData[comment].answers &&
            Object.keys(displayedData[comment].answers).length !== 0 && (
              <Grid
                container
                sx={{
                  marginLeft: "27px",
                  marginTop: "8px",
                  paddingRight: "16px",
                }}
                spacing={1}
                direction="column-reverse"
              >
                {Object.keys(displayedData[comment].answers).map((answer) => {
                  return (
                    <Grid item key={displayedData[comment].answers[answer]._id}>
                      <CommentCard
                        data={displayedData[comment].answers[answer]}
                        handleOpenCreateCommentDrawer={
                          handleOpenCreateCommentDrawer
                        }
                        handleOpenEditCommentDrawer={
                          handleOpenEditCommentDrawer
                        }
                        handleOpenDeleteDialog={() =>
                          handleOpenDeleteDialog(
                            displayedData[comment].answers[answer]
                          )
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
        </Grid>
      ))}
    </>
  );
};

export default CommentsList;
