import { useSearchParams } from "react-router-dom";
import colors from "../theme/colors";
import { UrlParamsEnum } from "../types/common";

const { EXTERNAL_URL } = UrlParamsEnum;

const useBindLinkClick = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSetSearchParams = (link: string) => {
    searchParams.set(EXTERNAL_URL, link);
    setSearchParams(searchParams);
  };

  const handleBindLinksClick = () => {
    const links = document.querySelectorAll<HTMLDivElement>("#parsed-link");

    if (links) {
      links.forEach((link) => {
        link.addEventListener("touchstart", () => {
          handleSetSearchParams(link.innerHTML);
        });
        link.style.color = colors.secondary;
        link.style.textDecoration = "underline";
        link.style.whiteSpace = "break-spaces";
        link.style.lineBreak = "anywhere";
      });
    }
  };

  return handleBindLinksClick;
};

export default useBindLinkClick;
