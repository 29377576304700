import { AppStateContext, AppActions, AppActionsEnum } from "./types";

const reducer = (
  state: AppStateContext,
  action: AppActions
): AppStateContext => {
  switch (action.type) {
    case AppActionsEnum.SET_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case AppActionsEnum.SET_GARDEN_ID:
      return {
        ...state,
        garden: action.payload,
      };
    case AppActionsEnum.SET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case AppActionsEnum.SET_SUBSCRIBED_GARDENS:
      return {
        ...state,
        subscribedGardens: action.payload,
      };
    case AppActionsEnum.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case AppActionsEnum.SET_GARDEN_COORDINATES:
      return {
        ...state,
        gardenCoordinates: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
