import ENDPOINTS from "./endpoints";
import { apiClient } from "./apiClient";
import {
  GenerateGetUrlData,
  GenerateGetUrlParams,
  GenerateGetUrlResponse,
  GeneratePutUrlData,
  GeneratePutUrlParams,
  GeneratePutUrlResponse,
} from "../types/FileUpload";

const awsEndpoint = ENDPOINTS.AWS;
const generatePutUrlEndpoint = ENDPOINTS.GENERATE_PUT_URL;
const generateGetUrlEndpoint = ENDPOINTS.GENERATE_GET_URL;

const generatePutUrl = (params: GeneratePutUrlParams): GeneratePutUrlResponse =>
  apiClient
    .post<GeneratePutUrlData>(
      `${awsEndpoint}/${generatePutUrlEndpoint}`,
      params
    )
    .then((res) => res.data);

const generateGetUrl = (params: GenerateGetUrlParams): GenerateGetUrlResponse =>
  apiClient
    .post<GenerateGetUrlData>(
      `${awsEndpoint}/${generateGetUrlEndpoint}`,
      params
    )
    .then((res) => res.data);

export default {
  generateGetUrl,
  generatePutUrl,
};
