import { CircularProgress, IconButton, Stack } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import ImageUpload from "../../../components/Media/ImageUpload";
import { TrashIcon } from "../../../icons";
import colors from "../../../theme/colors";
import { DisplayScreenEnum } from "../../../types/common";
import { CreatePostParams, FeedListItem } from "../../../types/Feed";

interface Props {
  editPostItem?: FeedListItem;
  setModel: Dispatch<SetStateAction<CreatePostParams>>;
  handleOpenDeleteDialog?(): void;
  isDeletingPost?: boolean;
}

const BottomMenu: React.FC<Props> = (props: Props) => {
  const { editPostItem, setModel, handleOpenDeleteDialog, isDeletingPost } =
    props;

  return (
    <>
      <Stack
        direction="row"
        justifyContent={editPostItem ? "space-between" : "flex-end"}
        alignItems="center"
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          height: "52px",
          width: "100%",
          backgroundColor: colors.background,
          padding: "0px 16px 0px 16px",
        }}
      >
        {editPostItem && (
          <IconButton
            onClick={handleOpenDeleteDialog}
            disabled={isDeletingPost}
          >
            {isDeletingPost ? (
              <CircularProgress color="secondary" size="25px" />
            ) : (
              <TrashIcon />
            )}
          </IconButton>
        )}
        <ImageUpload
          folder="feed"
          onChange={(link) =>
            setModel((prev) => ({
              ...prev,
              picture: [...prev.picture, link],
            }))
          }
          displayScreen={DisplayScreenEnum.FEED}
        />
      </Stack>
      {/* <DeleteDialog
        dialogTitle={texts.createPost.areYouSureYouWantToDeleteThisPost}
        handleDelete={handleDeletePost}
        open={isDeleteDialogOpen}
        setOpen={setIsDeleteDialogOpen}
        isLoading={isDeletingPost}
      /> */}
    </>
  );
};

export default BottomMenu;
