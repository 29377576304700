export interface BlobWithName extends Omit<Blob, "name"> {
  name: string;
}

export interface ApiPaginationParams {
  page: number;
  limit: number;
  q?: string;
  order?: string;
  sort?: string;
  id?: string;
  name?: string;
}
// eslint-disable-next-line
export interface ApiGetDataResponse<Item = any> {
  count: number;
  rows: Item[];
}

export enum UrlParamsEnum {
  FULL_SCREEN = "fullScreen",
  EXTERNAL_URL = "externalUrl",
  GARDEN_ID = "gardenId",
  UPDATE_GARDEN_ID = "updatedGardenId",
  FOCUS = "focus",
}

export enum DisplayScreenEnum {
  FEED = "FEED",
  EVENT = "EVENT",
}

export enum MapTypeEnum {
  ROAD_MAP = "roadmap",
  SATELLITE = "satellite",
}
