/* eslint-disable camelcase */
import { Grid } from "@mui/material";
import { useJsApiLoader } from "@react-google-maps/api";
import React, { useMemo, useState } from "react";
import useSWR from "swr";
import gardensApi from "../../api/gardensApi";
import ErrorPlaceholder from "../../components/ErrorPlaceholder";
import MapComponent from "../../components/Map";
import { ExistingGardensItem, GardensListItem } from "../../types/Gardens";
import GardenInfoCard from "./components/GardenInfoCard";

const FindGardens: React.FC = () => {
  const { data, error, mutate } = useSWR(["/gardens/list"], () =>
    gardensApi.getGardensList({
      limit: 100,
      page: 1,
    })
  );

  const { data: existingGardensData, error: existingGardensError } = useSWR(
    "/garden/public",
    () => gardensApi.getExistingGardensList({ page: 1, limit: 100 })
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
  });

  const [isOpenGardenInfoCard, setIsOpenGardenInfoCard] = useState(false);
  const [gardenInfo, setGardenInfo] = useState<GardensListItem>();

  const formatedGardensData: GardensListItem[] = useMemo(() => {
    if (!existingGardensData || existingGardensError) return [];
    return existingGardensData?.map(
      ({
        _id,
        geolocation,
        name,
        category,
        address,
        url,
        fb_url,
      }: ExistingGardensItem) => {
        return {
          id: _id,
          icon: "",
          ownerName: "",
          ownerDisplayName: "",
          countPlants: 0,
          sharedUsers: [],
          ownerPicture: "",
          subscribers: [],
          isActive: false,
          countGrowingCircles: 0,
          countTasks: 0,
          picture: "",
          description: "",
          type: "",
          owner: {
            _id: "",
            name: "",
            lastName: "",
            picture: "",
          },
          createdAt: "",
          locationCoordinates: {
            lat: geolocation.lat,
            lng: geolocation.lng,
          },
          name,
          category,
          location: address,
          imported: true,
          url,
          fb_url,
        };
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingGardensData]);

  if (error) {
    return <ErrorPlaceholder />;
  }

  return isLoaded && data && formatedGardensData ? (
    <Grid
      sx={{
        height: "calc(100vh - 88px)",
        "&: after": {
          content: "''",
          position: "absolute",
          background: isOpenGardenInfoCard ? "rgba(0, 0, 0, 0.5)" : "none",
          width: isOpenGardenInfoCard ? "100%" : 0,
          height: "100vh",
          top: 0,
          left: 0,
          opacity: isOpenGardenInfoCard ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
        },
      }}
    >
      {data && formatedGardensData && (
        <MapComponent
          data={data}
          mutate={mutate}
          error={error}
          existingGardens={formatedGardensData}
        />
      )}
      {gardenInfo && (
        <GardenInfoCard
          open={isOpenGardenInfoCard}
          setOpen={setIsOpenGardenInfoCard}
          data={
            data.rows.find((garden) => garden.id === gardenInfo.id) ||
            formatedGardensData.find((garden) => garden.id === gardenInfo.id)
          }
          setGardenInfo={setGardenInfo}
          mutate={mutate}
        />
      )}
    </Grid>
  ) : null;
};

export default FindGardens;
