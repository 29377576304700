export default {
  createPost: "Create Post",
  editPost: "Edit Post",
  post: "Post",
  postCreatedSuccessfully: "New post created successfully",
  postUpdatedSuccessfully: "Post was updated successfully",
  postDeletedSuccessfully: "Post was deleted successfully",
  areYouSureYouWantToDeleteThisPost:
    "Are you sure you want to delete this post?",
  takePhoto: "Take Photo",
  uploadImage: "Upload Image",
};
