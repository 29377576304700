import React from "react";
import { Stack, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import texts from "../../../constants/texts";
import { CommentListItem } from "../../../types/Comments";

const useStyles = makeStyles({
  input: {
    "& input.Mui-disabled": {
      textFillColor: "#fff",
    },
  },
});

interface Props {
  handleOpenCreateCommentDrawer(comment: CommentListItem | undefined): void;
}

const CreateCommentTextField: React.FC<Props> = ({
  handleOpenCreateCommentDrawer,
}: Props) => {
  const classes = useStyles();

  return (
    <Stack direction="row" alignItems="center">
      <TextField
        fullWidth
        placeholder={texts.localizations.leaveAComment}
        className={classes.input}
        disabled
        onClick={() => handleOpenCreateCommentDrawer(undefined)}
      />
    </Stack>
  );
};

export default CreateCommentTextField;
