import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Box } from "@mui/material";
import { AppStoreProvider } from "../store";
import Theme from "./Theme";
import UserDataWrapper from "./UserDataWrapper";
import Loader from "../components/Loader";
import { AnimatedRoutes } from "./AnimatedRoutes";

const App: React.FC = () => {
  return (
    <Box
      sx={{
        maxWidth: "1024px",
        background: "#2D9075",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <AppStoreProvider>
        <Theme>
          <UserDataWrapper>
            <Suspense fallback={<Loader inline={false} height="100vh" />}>
              <BrowserRouter>
                <AnimatedRoutes />
              </BrowserRouter>
            </Suspense>
          </UserDataWrapper>
        </Theme>
      </AppStoreProvider>
    </Box>
  );
};

export default App;
