import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { getParsedLinks } from "../helpers/common";
import useBindLinkClick from "../hooks/useBindLinkClick";
import colors from "../theme/colors";

interface Props {
  text: string;
}

export const ParsedLinksText = ({ text }: Props): React.ReactElement => {
  const handleBindLinksClick = useBindLinkClick();

  useEffect(() => {
    handleBindLinksClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Typography sx={{ color: colors.white, lineBreak: "anywhere" }}>
      {getParsedLinks(text, true)}
    </Typography>
  );
};
