export default {
  comments: "Comments",
  commentCreatedSuccessfully: "Comment created successfully",
  commentDeletedSuccessfully: "Comment removed successfully",
  reply: "Reply...",
  expert: "Expert",
  editComment: "Edit Comment",
  createComment: "Create Comment",
  areYouSureYouWantDeleteThisComment:
    "Are you sure you want to delete this comment?",
};
