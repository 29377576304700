import basic from "./basic";
import createPost from "./createPost";
import imageUpload from "./imageUpload";
import feed from "./feed";
import findGardens from "./findGardens";
import comments from "./comments";
import map from "./map";
import localizations from "./localizations";
import events from "./events";
import informationModal from "./informationModal";

export default {
  basic,
  createPost,
  imageUpload,
  feed,
  findGardens,
  comments,
  map,
  localizations,
  events,
  informationModal,
};
