import React from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { v4 as uuid } from "uuid";
import texts from "../../constants/texts";
import { AddReactionIcon } from "../../icons";
import { ReactionsListData, ReactListItemToObj } from "../../types/Reactions";
import colors from "../../theme/colors";
import { FeedListItem } from "../../types/Feed";

interface Props {
  error?: boolean;
  data: ReactionsListData;
  displayedData: ReactListItemToObj;
  isLoading?: boolean;
  isRemovingReaction?: boolean;
  addOrRemoveReactionHandler(reaction: string): void;
  setIsOpenReactionsDrawer(v: boolean): void;
  myRec: string;
  postData: FeedListItem;
}

const ReactionsList: React.FC<Props> = (props: Props) => {
  const {
    error,
    data,
    displayedData,
    isLoading,
    isRemovingReaction,
    addOrRemoveReactionHandler,
    setIsOpenReactionsDrawer,
    myRec,
    postData,
  } = props;

  return (
    <Grid
      sx={{
        padding: "0px 12px 0px 17px",
        marginBottom: "17px",
        marginTop: postData.picture.length > 1 ? "35px" : "10px",
      }}
    >
      {error ? (
        <Typography>{texts.feed.cantLoadReaction}</Typography>
      ) : (
        <Grid container>
          {data &&
            Object.keys(displayedData).map((reaction) => (
              <Grid
                key={uuid()}
                item
                sx={{
                  padding: "0px 8px 8px 8px",
                  background:
                    myRec === reaction
                      ? colors.secondary
                      : "rgba(39, 60, 78, 0.23)",
                  height: "24px",
                  borderRadius: "8px",
                  margin: "0px 8px 0px 0px",
                }}
                onClick={() => addOrRemoveReactionHandler(reaction)}
              >
                <Typography
                  variant="caption"
                  sx={{
                    color: myRec === reaction ? "#000" : "#fff",
                  }}
                >
                  {reaction}
                  {displayedData[reaction]}
                </Typography>
              </Grid>
            ))}
          <Grid
            item
            sx={{
              padding: "0px 8px 8px 8px",
              background: "rgba(39, 60, 78, 0.23)",
              height: "24px",
              borderRadius: "8px",
            }}
          >
            {isLoading || isRemovingReaction ? (
              <CircularProgress
                color="secondary"
                size={15}
                sx={{ marginTop: "5px" }}
              />
            ) : (
              <AddReactionIcon onClick={() => setIsOpenReactionsDrawer(true)} />
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ReactionsList;
