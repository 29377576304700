import { TextareaAutosize } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import texts from "../../constants/texts";
import colors from "../../theme/colors";

const useStyles = makeStyles({
  textArea: {
    width: "100%",
    background: "none",
    boxShadow: "none",
    border: "none",
    resize: "none",
    outline: "none",
    color: colors.white,
    fontSize: "15px",
    // marginTop: "31px",
    "&::placeholder": {
      color: colors.inputPlaceholderColor,
    },
  },
});

interface Props {
  value: string;
  onChange(v: string): void;
}

const AutosizeInput: React.FC<Props> = (props: Props) => {
  const { value, onChange } = props;
  const classes = useStyles();

  return (
    <TextareaAutosize
      placeholder={texts.localizations.writeSomething}
      className={classes.textArea}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      autoFocus
      style={{
        maxHeight: "calc(100vh - 154px)",
        overflowY: "scroll",
      }}
    />
  );
};

export default AutosizeInput;
