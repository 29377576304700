import {
  CommentsListResponse,
  CommentsListData,
  CommentCreateParams,
  CommentListItem,
} from "../types/Comments";
import { apiClient } from "./apiClient";
import ENDPOINTS from "./endpoints";

const endpoint = ENDPOINTS.COMMENTS;

const getListOfComment = (postId: string): CommentsListResponse =>
  apiClient
    .get<CommentsListData>(`${endpoint}/${postId}/list`)
    .then((res) => res.data);

const createComment = (
  params: CommentCreateParams,
  postId: string
): Promise<CommentListItem> =>
  apiClient
    .post<CommentListItem>(`${endpoint}/${postId}`, params)
    .then((res) => res.data);

const updateComment = (
  params: CommentCreateParams,
  commentId: string
): Promise<CommentListItem> =>
  apiClient
    .put<CommentListItem>(`${endpoint}/${commentId}`, params)
    .then((res) => res.data);

const deleteComment = (commentId: string): Promise<void> =>
  apiClient.delete<void>(`${endpoint}/${commentId}`).then((res) => res.data);

export default {
  getListOfComment,
  createComment,
  deleteComment,
  updateComment,
};
