import { ReactionsListItem } from "../types/Reactions";
import { GardensListItem } from "../types/Gardens";
import socket from "./socket";

const emitReactionIncrement = (
  newReaction: string,
  postId: string,
  category: string,
  garden: GardensListItem | undefined
) => {
  socket.emit({
    event: "REACTION::INC",
    payload: { room: postId, reaction: newReaction },
  });
  socket.emit({
    event: "REACTION::INC",
    payload: { room: garden?.id || category, reaction: newReaction, postId },
  });
};

const emitReactionDecrement = (
  postId: string,
  myRec: string,
  category: string,
  garden: GardensListItem | undefined
) => {
  socket.emit({
    event: "REACTION::DEC",
    payload: { room: postId, reaction: myRec },
  });
  socket.emit({
    event: "REACTION::DEC",
    payload: { room: garden?.id || category, reaction: myRec, postId },
  });
};

export interface EmitReactionSetParams {
  increment?: ReactionsListItem["reaction"];
  decrement?: ReactionsListItem["reaction"];
  postId: string;
  reactions: ReactionsListItem[];
  garden: GardensListItem | undefined;
  category: string;
}

const emitReactionSet = ({
  postId,
  garden,
  category,
  ...rest
}: EmitReactionSetParams) => {
  socket.emit({
    event: "REACTION::SET",
    payload: { room: postId, postId, ...rest },
  });
  socket.emit({
    event: "REACTION::SET",
    payload: { room: garden?.id || category, postId, ...rest },
  });
};

export { emitReactionIncrement, emitReactionDecrement, emitReactionSet };
