import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const AskTheExpertIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.9307 9.38868L19.2773 2.76384C19.0619 1.89977 18.4902 1.15785 17.7096 0.729096C16.928 0.30144 15.9972 0.214669 15.1506 0.498315L14.5946 0.683524C14.2887 0.785242 14.1236 1.11592 14.2257 1.42144L14.4102 1.97488C14.5119 2.2804 14.8422 2.44555 15.1477 2.34384L15.6282 2.18378C16.0245 2.0518 16.4668 2.05363 16.8372 2.24686C17.2123 2.44264 17.4778 2.77769 17.5798 3.18821L18.982 8.79623C18.173 8.54795 17.1668 8.34196 16.0216 8.34196C14.754 8.34196 13.3244 8.5979 11.8344 9.31686H9.16635C7.6763 8.59717 6.24677 8.34159 4.97875 8.34159C3.83323 8.34159 2.82698 8.54758 2.01797 8.79587L3.42016 3.18748C3.52187 2.77733 3.78802 2.44191 4.16318 2.24649C4.53359 2.05326 4.97547 2.05144 5.37177 2.18342L5.85193 2.34347C6.15745 2.44519 6.48776 2.28003 6.58948 1.97451L6.77396 1.42108C6.87604 1.11555 6.71089 0.784878 6.405 0.683159L5.84901 0.497951C5.00245 0.214305 4.07167 0.300711 3.29 0.728732C2.50943 1.15785 1.93776 1.89941 1.72229 2.76311L0.0692708 9.38868C0.0233078 9.57341 4.57654e-05 9.76305 0 9.95342L0 12.5146C0 14.8078 1.88052 16.6669 4.2 16.6669H5.55333C7.75104 16.6669 9.57724 14.992 9.74167 12.8252L9.84849 11.4169H11.1519L11.2587 12.8252C11.4228 14.992 13.249 16.6669 15.4467 16.6669H16.8C19.1195 16.6669 21 14.8078 21 12.515V9.95378C21 9.76311 20.9767 9.57352 20.9307 9.38868ZM7.4149 12.6488C7.34307 13.5934 6.52531 14.3335 5.55333 14.3335H4.2C3.17078 14.3335 2.33333 13.5176 2.33333 12.5146V11.146C2.99396 10.9094 3.91635 10.6749 4.97948 10.6749C5.84937 10.6749 6.7025 10.8328 7.52901 11.146L7.4149 12.6488ZM18.6667 12.515C18.6667 13.5179 17.8292 14.3335 16.8 14.3335H15.4467C14.4743 14.3335 13.6566 13.5934 13.5851 12.6488L13.471 11.146C14.2975 10.8328 15.151 10.6749 16.0212 10.6749C17.0829 10.6749 18.0057 10.9097 18.6663 11.1463V12.515H18.6667Z"
      fill="white"
      fillOpacity="0.74"
    />
  </SvgIcon>
);

export default AskTheExpertIcon;
