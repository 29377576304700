import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Slider from "react-slick";
import ImageAsync from "../Media/ImageAsync";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import colors from "../../theme/colors";

interface Props {
  images: string[];
}

const useStyles = makeStyles({
  root: {
    "&.slick-slider .slick-list .slick-track": {},
    "&.slick-slider .slick-list .slick-track .slick-slide": {
      // width: "100%!important",
    },
  },
  dots: {
    "& li.slick-active button::before": {
      color: `${colors.secondary}!important`,
    },
    "& li button::before": {
      color: `${colors.secondary}!important`,
      opacity: 0.5,
    },
  },
});

const ImageSlider: React.FC<Props> = (props: Props) => {
  const { images } = props;

  const classes = useStyles();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: `slick-dots ${classes.dots}`,
  };

  return (
    <Slider {...settings} className={classes.root}>
      {images.map((item) => (
        <Grid key={item} sx={{ height: "250px" }}>
          <ImageAsync src={item} alt={item} />
        </Grid>
      ))}
    </Slider>
  );
};

export default ImageSlider;
