import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const BackToUserLocationIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_4538_19252)">
      <path
        d="M19.847 0C19.6008 0 19.349 0.0472656 19.0998 0.15125L1.23464 8.39652C-0.826569 9.35859 -0.139498 12.3819 2.05921 12.3819H9.61784V19.9405C9.61784 21.23 10.6581 22 11.7121 22C12.4555 22 13.2053 21.6176 13.6032 20.7651L21.8489 2.89953C22.432 1.49918 21.2323 0 19.847 0ZM11.6791 19.9405V10.3207H9.61784L2.07769 10.3512C2.06609 10.3353 2.05749 10.2932 2.09831 10.2682L19.8534 2.07367C19.8796 2.09129 19.9084 2.11965 19.926 2.14586L11.6791 19.9405Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4538_19252">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default BackToUserLocationIcon;
