import { Grid, Hidden, Skeleton, Typography } from "@mui/material";
import React, { useState } from "react";
import useSWR from "swr";
import fileUploadApi from "../../api/fileUploadApi";

interface StyleParams {
  [key: string]: string | number;
}
interface Props {
  src: string;
  alt: string;
  style?: StyleParams;
}

const ImageAsync: React.FC<Props> = (props: Props) => {
  const { src, alt, style, ...rest } = props;

  const async = src.indexOf("http") === -1;

  const { data, error } = useSWR(async ? src : null, () =>
    fileUploadApi.generateGetUrl({ key: src })
  );

  const [isBroken, setIsBroken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleBrokenLink = () => {
    setIsBroken(true);
    setIsLoading(false);
  };

  const handleLoaded = () => {
    setIsLoading(false);
  };

  if (isBroken || !src || error) {
    // add normal image placeholder
    return (
      <Grid sx={{ width: "100%", height: "auto", background: "#333" }}>
        <Typography>Cant Load This Image</Typography>
      </Grid>
    );
  }

  return (
    <span>
      {!data ? (
        <Hidden
          implementation="js"
          {...(!isLoading && {
            xsDown: true,
            xsUp: true,
          })}
        >
          <Skeleton
            variant="rectangular"
            sx={{
              width: "100%",
              height: "300px",
            }}
          />
        </Hidden>
      ) : (
        <>
          <img
            src={async ? data?.url : src}
            alt={alt}
            {...rest}
            onError={handleBrokenLink}
            onLoad={handleLoaded}
            {...(isLoading && {
              style: {
                display: "none",
              },
            })}
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "250px",
              objectFit: "cover",
              position: "relative",
              display: "block",
              ...style,
            }}
          />
          {/* <IconButton sx={{ position: "absolute", top: 0, left: 0 }}>
            <DeleteIcon sx={{ color: "#fff" }} />
          </IconButton> */}
        </>
      )}
    </span>
  );
};

export default ImageAsync;
