import {
  ReactionsListResponse,
  ReactionsListData,
  MyReaction,
} from "../types/Reactions";
import { apiClient } from "./apiClient";
import ENDPOINTS from "./endpoints";

const endpoint = ENDPOINTS.REACTIONS;

const getListOfReactions = (postId: string): ReactionsListResponse =>
  apiClient
    .get<ReactionsListData>(`${endpoint}/${postId}`)
    .then((res) => res.data);

const createReaction = (
  postId: string,
  reaction: string
): Promise<MyReaction> =>
  apiClient
    .post<MyReaction>(`${endpoint}/${postId}`, { reaction })
    .then((res) => res.data);

const removeReaction = (postId: string): Promise<void> =>
  apiClient.delete<void>(`${endpoint}/${postId}`).then((res) => res.data);

export default { getListOfReactions, createReaction, removeReaction };
