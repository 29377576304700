import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const InfoIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0.5C5.37305 0.5 0 5.87498 0 12.5C0 19.1289 5.37305 24.5 12 24.5C18.627 24.5 24 19.1289 24 12.5C24 5.87498 18.627 0.5 12 0.5ZM12 5.82258C13.1224 5.82258 14.0323 6.73245 14.0323 7.85484C14.0323 8.97723 13.1224 9.8871 12 9.8871C10.8776 9.8871 9.96774 8.97723 9.96774 7.85484C9.96774 6.73245 10.8776 5.82258 12 5.82258ZM14.7097 18.1129C14.7097 18.4336 14.4497 18.6935 14.129 18.6935H9.87097C9.55031 18.6935 9.29032 18.4336 9.29032 18.1129V16.9516C9.29032 16.631 9.55031 16.371 9.87097 16.371H10.4516V13.2742H9.87097C9.55031 13.2742 9.29032 13.0142 9.29032 12.6935V11.5323C9.29032 11.2116 9.55031 10.9516 9.87097 10.9516H12.9677C13.2884 10.9516 13.5484 11.2116 13.5484 11.5323V16.371H14.129C14.4497 16.371 14.7097 16.631 14.7097 16.9516V18.1129Z"
      fill="white"
    />
  </SvgIcon>
);

export default InfoIcon;
