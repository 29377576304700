export default {
  findGardens: "Find Gardens",
  post: "Post",
  comments: "Comments",
  addReaction: "Add Reaction",
  cantLoadReaction: "Can`t load reactions...",
  showNewPosts: "Show new posts",
  osloArea: "Oslo Area",
  askTheExpert: "Ask the expert",
  readMore: "Read more",
  hide: "Hide",
};
