const ENDPOINTS = {
  USERS: "/users",
  GENERATE_PUT_URL: "/generate-put-url",
  GENERATE_GET_URL: "/generate-get-url",
  AWS: "/aws",
  POSTS: "/posts",
  GARDENS: "/gardens",
  COMMENTS: "/comments",
  REACTIONS: "/reactions",
  EVENTS: "/events",
};

export default ENDPOINTS;
