import moment from "moment";

const calendarFormats = {
  sameElse: "lll",
};

const getMomentCalendarDate = (date: string): string =>
  moment(date).calendar(calendarFormats);

const getMomentFormattedDate = (date: string): string =>
  moment(date).format("ll");

const getMomentFormattedDateTime = (date: string): string =>
  moment(date).format("ll HH:mm");

const getMomentFormattedDuration = (duration: number): string =>
  moment.utc(duration).format("HH:mm:ss");

const getMomentFormattedDateNow = (): string => moment().format("DD-MM-YYYY");

const getMomentFormattedSingleDayAndTime = (
  startDate: Date,
  endDate: Date
): string => {
  const month = moment(startDate).startOf("month").format("MMMM");
  const date = moment(startDate).format("D");
  const startTime = moment(startDate).format("HH:mm");
  const endTime = moment(endDate).format("HH:mm");

  return `${month} ${date}th • ${startTime} - ${endTime}`;
};

const getMomentFormattedStartEndDayAndTime = (
  startDate: Date,
  endDate: Date
): string => {
  const start = moment(startDate).format("D MMM YYYY HH:mm");
  const end = moment(endDate).format("D MMM YYYY HH:mm");

  return `${start} - ${end}`;
};

const getMomentDateMonthYear = (date: Date): string =>
  moment(date).format("D MMM YY");

const getMomentTime = (date: Date): string => moment(date).format("HH:mm");

const getMomentDatePickerDate = (date: Date): string =>
  moment(date).format("YYYY-MM-DD");

export {
  calendarFormats,
  getMomentCalendarDate,
  getMomentFormattedDate,
  getMomentFormattedDuration,
  getMomentFormattedDateNow,
  getMomentFormattedDateTime,
  getMomentFormattedSingleDayAndTime,
  getMomentFormattedStartEndDayAndTime,
  getMomentDateMonthYear,
  getMomentTime,
  getMomentDatePickerDate,
};
