import React from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { Box } from "@mui/material";
import { useAppContext } from "../../store";
import userLocationMarker from "../../assets/icons8-street-view-96.png";
import { MapTypeEnum } from "../../types/common";
import colors from "../../theme/colors";
import {
  BackToUserLocationIcon,
  RoadMapIcon,
  SatelliteIcon,
} from "../../icons";
import { NewMarkerModel } from "../../containers/SelectGarden";

interface Props {
  onLoad(map: google.maps.Map): void;
  map: google.maps.Map | undefined;
  handleSetMapCenter(map: google.maps.Map): void;
  handleToggleMapType(): void;
  mapType: MapTypeEnum;
  // eslint-disable-next-line
  handleAddMarker?(e: any): void;
  newMarkerModel?: NewMarkerModel;
}

const Map: React.FC<Props> = (props: Props) => {
  const {
    onLoad,
    map,
    handleSetMapCenter,
    handleToggleMapType,
    mapType,
    handleAddMarker,
    newMarkerModel,
  } = props;

  const {
    state: { gardenCoordinates },
  } = useAppContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const marker = new google.maps.Marker({
    position: gardenCoordinates,
    icon: {
      url: userLocationMarker,
      scaledSize: new google.maps.Size(50, 50),
    },
    map,
  });

  return (
    <>
      <GoogleMap
        mapContainerStyle={{
          height: "calc(100vh - 68px)",
        }}
        onLoad={onLoad}
        options={{
          mapId: "b150a04580bb87e9",
          disableDefaultUI: true,
          mapTypeId: mapType,
        }}
        id={process.env.REACT_APP_GOOGLE_MAP_ID}
        onClick={handleAddMarker}
      >
        <Box sx={{ position: "absolute", top: 10, right: 20 }}>
          <Box
            sx={{
              width: "48px",
              height: "48px",
              background: colors.primary,
              borderRadius: "8px 8px 0px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleToggleMapType}
          >
            {mapType === MapTypeEnum.ROAD_MAP ? (
              <SatelliteIcon />
            ) : (
              <RoadMapIcon />
            )}
          </Box>
          <Box
            sx={{
              width: "48px",
              height: "48px",
              background: colors.primary,
              borderRadius: "0px 0px 8px 8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1px",
            }}
            onClick={() => handleSetMapCenter(map as google.maps.Map)}
          >
            <BackToUserLocationIcon />
            <Box />
          </Box>
        </Box>
        {newMarkerModel && newMarkerModel.lat !== 0 && (
          <Marker
            position={{ lat: newMarkerModel.lat, lng: newMarkerModel.lng }}
            icon={require("../../assets/gardenPin.png")}
          >
            {newMarkerModel.location.length !== 0 && (
              <InfoWindow>
                <span>{newMarkerModel.location}</span>
              </InfoWindow>
            )}
          </Marker>
        )}
      </GoogleMap>
    </>
  );
};

export default Map;
