import { createTheme, Theme } from "@mui/material";
import colors from "./colors";

const createThemeComponent = (): Theme =>
  createTheme({
    palette: {
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            background: colors.primary,
            borderRadius: 0,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: colors.white,
            fontFamily: '"Inter", sans-serif',
          },
          body1: {
            color: colors.textSecondaryWhite,
            fontSize: "18px",
            "@media(max-width: 768px)": {
              fontSize: "15px",
            },
          },
          body2: {
            fontSize: "20px",
            "@media(max-width: 768px)": {
              fontSize: "17px",
            },
          },
          h1: {
            fontWeight: 500,
            fontSize: 44,
          },
          h2: {
            fontWeight: 500,
            fontSize: 38,
          },
          h3: {
            fontSize: 26,
          },
          subtitle1: {
            fontSize: 18,
            fontWeight: 500,
          },
          subtitle2: {
            fontSize: 15,
            fontWeight: 400,
          },
          caption: {
            fontSize: "18px",
            color: colors.white,
            letterSpacing: "-2%",
            lineHeight: "20px",
            "@media(max-width: 768px)": {
              fontSize: "14px",
            },
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          title: {
            fontSize: "21px",
            fontStyle: "regular",
            fontWeight: "500",
            "@media(max-width: 768px)": {
              fontSize: "15px",
            },
          },
          subheader: {
            color: "rgba(255, 255, 255, 0.8)",
            fontSize: "18px",
            "@media(max-width: 768px)": {
              fontSize: "15px",
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            fontSize: "15px",
            border: "none",
            color: "#fff",
            height: "48px",
            background: colors.textFieldBackground,
            borderRadius: "8px",
            "&.MuiOutlinedInput-root.Mui-error": {
              // borderColor: "rgba(251, 222, 80, 1)!important",
            },
            "&.Mui-error": {
              border: "1px solid rgba(251, 222, 80, 1)!important",
            },
            "& fieldset": {
              display: "none",
            },
          },
          notchedOutline: {
            border: `1px solid ${colors.textFieldBorder}`,
          },
        },
      },

      MuiFormControl: {
        styleOverrides: {
          root: {
            "& .MuiFormHelperText-root.Mui-error": {
              color: "rgba(251, 222, 80, 1)",
              marginLeft: "0px",
              fontSize: "15px",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: colors.white,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            background: colors.background,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: colors.white,
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            display: "flex",
            alignItems: "center",
            padding: 10,
            "& .MuiSwitch-switchBase": {
              width: "40px",
              transitionDuration: "300ms",
              "&.Mui-checked": {
                transform: "translateX(16px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                  backgroundColor: colors.checkedSwitchBg,
                  opacity: 1,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                  opacity: 0.5,
                },
              },
              "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: "6px solid #fff",
              },
            },
            "& .MuiSwitch-track": {
              borderRadius: "24px",
              height: "22px",
              backgroundColor: colors.textFieldBackground,
              opacity: 1,
            },
            "& .MuiSwitch-thumb": {
              boxShadow: "none",
              width: 18,
              height: 18,
              margin: 1,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
            color: colors.white,
            fontSize: "18px",
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {},
          paper: {
            background: colors.primary,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "contained",
        },
        styleOverrides: {
          root: {
            textTransform: "none",
            fontSize: "18px",
            color: "#FFE773",
            "&:disabled": {
              color: "#A6C069",
            },
            height: "50px",
          },
          contained: {
            background: colors.secondary,
            color: colors.buttonTextColor,
            textTransform: "none",
            fontSize: "15px",
            ":disabled": {
              background: "#dacb7e!important",
              color: colors.background,
            },
            ":hover": {
              background: colors.secondary,
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {},
        },
      },
    },
  });

export default createThemeComponent;
