import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const SearchIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.2537 15.5622L14.7489 12.0575C14.5907 11.8993 14.3763 11.8114 14.1513 11.8114H13.5783C14.5485 10.5705 15.125 9.00972 15.125 7.31183C15.125 3.27275 11.8523 0 7.81321 0C3.77413 0 0.501381 3.27275 0.501381 7.31183C0.501381 11.3509 3.77413 14.6237 7.81321 14.6237C9.5111 14.6237 11.0719 14.0471 12.3128 13.0769V13.6499C12.3128 13.8749 12.4007 14.0893 12.5589 14.2475L16.0636 17.7523C16.3941 18.0827 16.9284 18.0827 17.2553 17.7523L18.2501 16.7574C18.5806 16.427 18.5806 15.8927 18.2537 15.5622ZM7.81321 11.8114C5.32789 11.8114 3.31362 9.80066 3.31362 7.31183C3.31362 4.82651 5.32437 2.81224 7.81321 2.81224C10.2985 2.81224 12.3128 4.82299 12.3128 7.31183C12.3128 9.79715 10.302 11.8114 7.81321 11.8114Z"
      fill="white"
    />
  </SvgIcon>
);

export default SearchIcon;
