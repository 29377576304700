import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const ArrowRightIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.5"
      d="M7 6C7 5.625 2.9226 0.40625 2.9226 0.40625C2.77739 0.15625 2.40439 0 1.9659 0L0.00692415 0C-0.205984 0 4.5618 5.625 4.5618 6C4.5618 6.375 -0.205984 12 0.0752602 12C0.356504 12 1.9659 12 1.9659 12C2.33036 12 2.77739 11.8438 2.9226 11.5938C2.9226 11.5938 7 6.375 7 6Z"
      fill="white"
    />
  </SvgIcon>
);

export default ArrowRightIcon;
