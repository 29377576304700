import { Avatar, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import Slider from "react-slick";
import ErrorPlaceholder from "../../../components/ErrorPlaceholder";
import texts from "../../../constants/texts";
import { getTrimmedText } from "../../../helpers/common";
import { AskTheExpertIcon, OsloIcon } from "../../../icons";
import { useAppContext } from "../../../store";
import { setSubscribedGardens } from "../../../store/actions";
import { FeedCategoryEnum } from "../../../types/Feed";
import {
  GardensListItem,
  GardensSubscribedListData,
} from "../../../types/Gardens";
import SliderItem from "./SliderItem";

const useStyles = makeStyles({
  root: {
    marginTop: "31px",
    paddingLeft: "17px",
    paddingRight: "17px",

    "&.slick-slider .slick-list .slick-track": {
      minWidth: "100%!important",
    },
    "&.slick-slider .slick-list .slick-track .slick-slide": {
      // width: "100px!important",
      // minWidth: "100px!important",
    },
  },
});

interface Props {
  handleSelectedGardenChange(
    garden: GardensListItem | undefined,
    updatedCategory: FeedCategoryEnum
  ): void;
  selectedGarden: string | undefined;
  subscribedGardensData: GardensSubscribedListData | undefined;
  subscribedGardensError?: boolean;
  handleToggleRevalidateData(): void;
}

const GardensSlider: React.FC<Props> = (props: Props) => {
  const {
    state: { garden, category },
    dispatch,
  } = useAppContext();

  const {
    handleSelectedGardenChange,
    selectedGarden,
    subscribedGardensData,
    subscribedGardensError,
    handleToggleRevalidateData,
  } = props;

  useEffect(() => {
    if (subscribedGardensData) {
      dispatch(setSubscribedGardens(subscribedGardensData));
    }
  }, [dispatch, subscribedGardensData]);

  const classes = useStyles();

  const isSmallTablet = useMediaQuery("(max-width:880px)");
  const isBigMobile = useMediaQuery("(max-width:660px)");
  const isMobile = useMediaQuery("(max-width:560px)");
  const isSmallMobile = useMediaQuery("(max-width:460px)");

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow:
      (isSmallMobile && 4) ||
      (isMobile && 5) ||
      (isBigMobile && 6) ||
      (isSmallTablet && 7) ||
      8,
    slidesToScroll: 1,
    // swipeToSlide: true,
  };

  const handleSliderItemClick = (
    selectedGarden: GardensListItem | undefined,
    category: FeedCategoryEnum
  ) => {
    if (selectedGarden === garden) {
      handleToggleRevalidateData();
    } else {
      handleSelectedGardenChange(selectedGarden, category);
    }
  };

  if (subscribedGardensError) {
    return <ErrorPlaceholder />;
  }

  return (
    <Grid sx={{ height: "90px" }}>
      {subscribedGardensData && (
        <Slider {...settings} className={classes.root}>
          <SliderItem
            handleSelectedGardenChange={() =>
              handleSliderItemClick(undefined, FeedCategoryEnum.OSLO)
            }
            icon={<OsloIcon />}
            isSelected={
              garden === undefined && category === FeedCategoryEnum.OSLO
            }
            text={texts.feed.osloArea}
          />
          {subscribedGardensData.map((garden) => (
            <Grid
              sx={{
                borderBottom:
                  garden.id === selectedGarden ? "3px solid #FBDE50" : "none",
                display: "flex!important",
                flexDirection: "column",
              }}
              key={garden.id}
              onClick={() =>
                handleSliderItemClick(garden, FeedCategoryEnum.GARDEN)
              }
              container
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Avatar
                  sx={{ width: "49px", height: "49px" }}
                  src={garden.icon}
                  alt={garden.name}
                />
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: "12px!important",
                  }}
                >
                  {getTrimmedText(garden.name, 10)}
                </Typography>
              </Grid>
            </Grid>
          ))}
          <SliderItem
            handleSelectedGardenChange={() =>
              handleSliderItemClick(undefined, FeedCategoryEnum.ASK_EXPERT)
            }
            icon={<AskTheExpertIcon />}
            isSelected={
              garden === undefined && category === FeedCategoryEnum.ASK_EXPERT
            }
            text={texts.localizations.askTheExpert}
          />
        </Slider>
      )}
    </Grid>
  );
};

export default GardensSlider;
