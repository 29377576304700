import React, { lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import PostDetails from "../containers/Feed/components/PostDetails";
import { appLinks } from "../routes/routes";
import FindGardensContainer from "../containers/FindGardens/components/FindGardensContainer";
import Feed from "../containers/Feed";

const SearchGardens = lazy(
  () => import("../containers/SearchGardens/SearchGardens")
);
const SelectGarden = lazy(() => import("../containers/SelectGarden"));
const Events = lazy(() => import("../containers/Events"));

export const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route path={appLinks.index.link} element={<Feed />} />
        <Route path={appLinks.selectGarden.link} element={<SelectGarden />} />
        <Route
          path={appLinks.findGardens.link}
          element={<FindGardensContainer />}
        />
        <Route
          path={appLinks.findGardensSearch.link}
          element={<SearchGardens />}
        />
        <Route path={appLinks.postDetails.link} element={<PostDetails />} />
        <Route path={appLinks.events.link} element={<Events />} />
        <Route element={<div>No Found</div>} />
      </Routes>
    </AnimatePresence>
  );
};
