import React, { useMemo, useState } from "react";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { useSnackbar } from "notistack";
import fileUploadApi from "../../../api/fileUploadApi";
import texts from "../../../constants/texts";
import { getFileWithUniqueName } from "../../../helpers/common";
import { getMomentFormattedDateNow } from "../../../helpers/momentFormats";
import ImageInputForPost from "./ImageInputForPost";
import { DisplayScreenEnum } from "../../../types/common";
import EventImageUpload from "./EventImageUpload";

interface Props {
  folder: string;
  value?: string;
  onChange: (link: string) => void;
  isError?: boolean;
  disabled?: boolean;
  displayScreen: DisplayScreenEnum; // set UI based on this prop
}

const ImageUpload: React.FC<Props> = (props: Props) => {
  const { folder, value, onChange, isError, disabled, displayScreen } = props;

  const { enqueueSnackbar } = useSnackbar();
  const inputId = useMemo(() => uuid(), []);

  const [isUploading, setIsUploading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleUpload = async (file: File[]) => {
    setIsUploading(true);

    // eslint-disable-next-line
    for (const fileItem of file) {
      const key = `${folder}/${getMomentFormattedDateNow()}/${fileItem.name}`;

      try {
        /* eslint-disable no-await-in-loop */
        const { url } = await fileUploadApi.generatePutUrl({
          key,
          contentType: fileItem.type,
        });

        /* eslint-disable no-await-in-loop */
        await axios.request({
          method: "PUT",
          url,
          data: fileItem,
          headers: {
            "Content-Type": fileItem.type,
          },
        });

        if (key.length) {
          onChange(key);
        }
      } catch (e) {
        enqueueSnackbar(texts.imageUpload.cantUpload, { variant: "error" });
      }
    }

    setInputValue("");
    setIsUploading(false);
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      await handleUpload(
        Object.keys(e.target.files).map((file) =>
          // eslint-disable-next-line
          getFileWithUniqueName(e.target.files![file as any])
        )
      );
    }
  };

  return displayScreen === DisplayScreenEnum.FEED ? (
    <ImageInputForPost
      link={value}
      handleFileSelect={handleFileSelect}
      inputId={inputId}
      inputValue={inputValue}
      isUploading={isUploading}
      disabled={disabled}
      isError={isError}
    />
  ) : (
    <EventImageUpload
      link={value}
      handleFileSelect={handleFileSelect}
      inputId={inputId}
      inputValue={inputValue}
      isUploading={isUploading}
      disabled={disabled}
      isError={isError}
    />
  );
};

export default ImageUpload;
