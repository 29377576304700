export default {
  findGardens: "Find Gardens",
  visitGardenPage: "Visit garden page",
  navigateToGarden: "Navigate to Garden",
  garden: "Garden",
  joinGarden: "Join Garden",
  location: "Location",
  members: "Members",
  joined: "Joined Garden",
};
