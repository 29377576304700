import { FeedCategoryEnum } from "../types/Feed";
import { GardensListItem } from "../types/Gardens";
import { LanguageEnum, GardenCoordinates } from "../store/types";

const LocalStorageEnum = {
  LANGUAGE: "grow_app_language",
  GARDEN_COORDINATES: "grow_app_garden_coordinates",
  ACCESS_TOKEN: "grow_app_access_token",
  SELECTED_GARDEN_ID: "grow_app_selected_garden_id",
  CATEGORY: "grow_app_category",
};

const getCurrentLanguage = () =>
  (localStorage.getItem(LocalStorageEnum.LANGUAGE) ||
    LanguageEnum.NB) as LanguageEnum;

const setCurrentLanguage = (language: LanguageEnum) => {
  localStorage.setItem(LocalStorageEnum.LANGUAGE, language);
};

const getGardenCoordinates = (): string =>
  localStorage.getItem(
    LocalStorageEnum.GARDEN_COORDINATES
  ) as unknown as string;

const setGardenCoordinates = (coordinates: GardenCoordinates) =>
  localStorage.setItem(
    LocalStorageEnum.GARDEN_COORDINATES,
    JSON.stringify(coordinates)
  );

const getTokenFromLocalStorage = (): string => {
  return localStorage.getItem(LocalStorageEnum.ACCESS_TOKEN) as string;
};

const setTokenToLocalStorage = (token: string) =>
  localStorage.setItem(LocalStorageEnum.ACCESS_TOKEN, token);

const deleteTokenFromLocalStorage = () =>
  localStorage.removeItem(LocalStorageEnum.ACCESS_TOKEN);

const setSelectedGardenToLocalStorage = (garden: GardensListItem) =>
  localStorage.setItem(
    LocalStorageEnum.SELECTED_GARDEN_ID,
    JSON.stringify(garden)
  );

const getSelectedGardenFromLocalStorage = () =>
  localStorage.getItem(LocalStorageEnum.SELECTED_GARDEN_ID) as string;

const deleteSelectedGardenFromLocalStorage = () =>
  localStorage.removeItem(LocalStorageEnum.SELECTED_GARDEN_ID);

const setCategoryToLocalStorage = (category: FeedCategoryEnum) =>
  localStorage.setItem(LocalStorageEnum.CATEGORY, category);

const getCategoryFromLocalStorage = () =>
  localStorage.getItem(LocalStorageEnum.CATEGORY);

const deleteCategoryFromLocalStorage = () =>
  localStorage.removeItem(LocalStorageEnum.CATEGORY);

export {
  getCurrentLanguage,
  setCurrentLanguage,
  getGardenCoordinates,
  setGardenCoordinates,
  getTokenFromLocalStorage,
  setTokenToLocalStorage,
  deleteTokenFromLocalStorage,
  setSelectedGardenToLocalStorage,
  getSelectedGardenFromLocalStorage,
  deleteSelectedGardenFromLocalStorage,
  setCategoryToLocalStorage,
  getCategoryFromLocalStorage,
  deleteCategoryFromLocalStorage,
};
