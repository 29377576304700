import { GardensListItem, GardensSubscribedListData } from "../types/Gardens";
import { FeedCategoryEnum } from "../types/Feed";
import { UserData } from "../types/Users";
import {
  AppActions,
  AppActionsEnum,
  GardenCoordinates,
  LanguageEnum,
} from "./types";

const setUserData = (data: UserData): AppActions => ({
  type: AppActionsEnum.SET_USER_DATA,
  payload: data,
});

const setGarden = (garden: GardensListItem | undefined): AppActions => ({
  type: AppActionsEnum.SET_GARDEN_ID,
  payload: garden,
});

const setCategory = (category: FeedCategoryEnum): AppActions => ({
  type: AppActionsEnum.SET_CATEGORY,
  payload: category,
});

const setSubscribedGardens = (
  subscribedGardens: GardensSubscribedListData
): AppActions => ({
  type: AppActionsEnum.SET_SUBSCRIBED_GARDENS,
  payload: subscribedGardens,
});

const setLanguage = (language: LanguageEnum): AppActions => ({
  type: AppActionsEnum.SET_LANGUAGE,
  payload: language,
});

const setCoordinates = (coordinates: GardenCoordinates): AppActions => ({
  type: AppActionsEnum.SET_GARDEN_COORDINATES,
  payload: coordinates,
});

export {
  setUserData,
  setGarden,
  setCategory,
  setSubscribedGardens,
  setLanguage,
  setCoordinates,
};
