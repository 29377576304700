import { Grid, Typography } from "@mui/material";
import React from "react";
import basic from "../constants/texts/basic";

interface Props {
  height?: string;
}

const ErrorPlaceholder: React.FC<Props> = (props: Props) => {
  const { height } = props;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: height || "100vh" }}
    >
      <Grid item>
        <Typography variant="h3">{basic.cantLoadData}</Typography>
      </Grid>
    </Grid>
  );
};

export default ErrorPlaceholder;
