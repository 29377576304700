import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const Globus = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_204_19026)">
      <path
        d="M12 0C5.37097 0 0 5.37097 0 12C0 18.629 5.37097 24 12 24C18.629 24 24 18.629 24 12C24 5.37097 18.629 0 12 0ZM20.6758 7.74194H17.4242C17.1 5.97581 16.5774 4.4129 15.9145 3.15968C17.9903 4.07903 19.6742 5.70968 20.6758 7.74194ZM12 2.32258C12.9 2.32258 14.3516 4.31613 15.0581 7.74194H8.94194C9.64839 4.31613 11.1 2.32258 12 2.32258ZM2.32258 12C2.32258 11.3371 2.39032 10.6887 2.51613 10.0645H6.27581C6.22742 10.6984 6.19355 11.3371 6.19355 12C6.19355 12.6629 6.22742 13.3016 6.27581 13.9355H2.51613C2.39032 13.3113 2.32258 12.6629 2.32258 12ZM3.32419 16.2581H6.57581C6.9 18.0242 7.42258 19.5871 8.08548 20.8403C6.00968 19.921 4.32581 18.2903 3.32419 16.2581ZM6.57581 7.74194H3.32419C4.32581 5.70968 6.00968 4.07903 8.08548 3.15968C7.42258 4.4129 6.9 5.97581 6.57581 7.74194ZM12 21.6774C11.1 21.6774 9.64839 19.6839 8.94194 16.2581H15.0629C14.3516 19.6839 12.9 21.6774 12 21.6774ZM15.3919 13.9355H8.60806C8.55484 13.3161 8.51613 12.6774 8.51613 12C8.51613 11.3226 8.55484 10.6839 8.60806 10.0645H15.3968C15.45 10.6839 15.4887 11.3226 15.4887 12C15.4887 12.6774 15.4452 13.3161 15.3919 13.9355ZM15.9145 20.8403C16.5774 19.5871 17.0952 18.0242 17.4242 16.2581H20.6758C19.6742 18.2903 17.9903 19.921 15.9145 20.8403ZM17.7242 13.9355C17.7726 13.3016 17.8065 12.6629 17.8065 12C17.8065 11.3371 17.7726 10.6984 17.7242 10.0645H21.4839C21.6097 10.6887 21.6774 11.3371 21.6774 12C21.6774 12.6629 21.6097 13.3113 21.4839 13.9355H17.7242Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_204_19026">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Globus;
