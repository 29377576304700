import { Box, CircularProgress } from "@mui/material";
import React from "react";

interface Props {
  inline?: boolean;
  height?: string;
  inButton?: boolean;
}

const Loader: React.FC<Props> = (props: Props) => {
  const { inline = true, height = "auto", inButton = false } = props;

  if (inButton) {
    return <CircularProgress size={20} color="primary" />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: inline ? "auto" : height,
      }}
    >
      <CircularProgress color="secondary" />
    </Box>
  );
};

export default Loader;
