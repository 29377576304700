import React, { useContext, useMemo, useReducer, createContext } from "react";
import {
  getCategoryFromLocalStorage,
  getCurrentLanguage,
  getGardenCoordinates,
  getSelectedGardenFromLocalStorage,
} from "../helpers/localStorage";
import { FeedCategoryEnum } from "../types/Feed";
import reducer from "./reducer";
import { AppStateContext, IAppContext } from "./types";

const currentLanguage = getCurrentLanguage();
const gardenCoordinates = getGardenCoordinates();
const selectedGarden = getSelectedGardenFromLocalStorage();
const category = getCategoryFromLocalStorage() as FeedCategoryEnum;

const initialState: AppStateContext = {
  user: {
    address: "",
    age: 0,
    city: "",
    country: "",
    createdAt: "",
    displayName: "",
    email: "",
    expLevelGardening: "",
    firstName: "",
    gardensCount: 0,
    gender: "",
    id: "",
    isActive: false,
    isTester: false,
    language: "",
    lastName: "",
    picture: "",
    pushToken: [],
    state: "",
    testType: "",
    gardens: [],
    _id: "",
  },
  garden: selectedGarden ? JSON.parse(selectedGarden) : undefined,
  category: category || FeedCategoryEnum.OSLO,
  subscribedGardens: [],
  language: currentLanguage,
  gardenCoordinates: JSON.parse(gardenCoordinates as string) || {
    lat: 10,
    lng: 10,
  },
};

export const AppContext = createContext<IAppContext>({
  state: initialState,
  dispatch: () => null,
});

export const useAppContext = (): IAppContext => useContext(AppContext);

interface Props {
  children: React.ReactNode;
}

export const AppStoreProvider = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
