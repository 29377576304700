import { Dispatch } from "react";
import { GardensListItem, GardensSubscribedListData } from "../types/Gardens";
import { FeedCategoryEnum } from "../types/Feed";
import { UserData } from "../types/Users";

export interface AppStateContext {
  user: UserData;
  garden?: GardensListItem;
  category: FeedCategoryEnum;
  subscribedGardens: GardensSubscribedListData;
  language: LanguageEnum;
  gardenCoordinates: GardenCoordinates;
}

export enum LanguageEnum {
  EN = "en",
  NB = "nb",
}

export interface GardenCoordinates {
  lat: number;
  lng: number;
}

interface SetUserData {
  type: AppActionsEnum.SET_USER_DATA;
  payload: UserData;
}

interface SetGarden {
  type: AppActionsEnum.SET_GARDEN_ID;
  payload: GardensListItem | undefined;
}

interface SetCategory {
  type: AppActionsEnum.SET_CATEGORY;
  payload: FeedCategoryEnum;
}

interface SetSubscribedGardens {
  type: AppActionsEnum.SET_SUBSCRIBED_GARDENS;
  payload: GardensSubscribedListData;
}

export enum AppActionsEnum {
  SET_USER_DATA = "SET_USER_DATA",
  SET_GARDEN_ID = "SET_GARDEN_ID",
  SET_CATEGORY = "SET_CATEGORY",
  SET_SUBSCRIBED_GARDENS = "SET_SUBSCRIBED_GARDENS",
  SET_LANGUAGE = "SET_LANGUAGE",
  SET_GARDEN_COORDINATES = "SET_GARDEN_COORDINATES",
}

export interface IAppContext {
  state: AppStateContext;
  dispatch: Dispatch<AppActions>;
}

interface SetLanguage {
  type: AppActionsEnum.SET_LANGUAGE;
  payload: LanguageEnum;
}

interface SetGardenCoordinates {
  type: AppActionsEnum.SET_GARDEN_COORDINATES;
  payload: GardenCoordinates;
}

export type AppActions =
  | SetUserData
  | SetGarden
  | SetCategory
  | SetSubscribedGardens
  | SetLanguage
  | SetGardenCoordinates;
