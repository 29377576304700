import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";
import { KeyedMutator } from "swr";
import postsApi from "../../api/postsApi";
import ImageAsync from "../../components/Media/ImageAsync";
import texts from "../../constants/texts";
import socket from "../../helpers/socket";
import useApiRequest from "../../hooks/useApiRequest";
import { useAppContext } from "../../store";
import colors from "../../theme/colors";
import {
  FeedListItem,
  CreatePostParams,
  FeedListData,
  CreatePostPictureParams,
} from "../../types/Feed";
import BottomMenu from "./components/BottomMenu";
import { DeleteIcon } from "../../icons";
import { getTrimmedText } from "../../helpers/common";

const useStyles = makeStyles(() => ({
  textArea: {
    width: "100%",
    background: "none",
    boxShadow: "none",
    border: "none",
    resize: "none",
    outline: "none",
    color: colors.white,
    fontSize: "15px",
    marginTop: "31px",
    "&::placeholder": {
      color: colors.inputPlaceholderColor,
    },
  },
  textAreaImage: {
    width: "100%",
    background: "none",
    boxShadow: "none",
    border: "none",
    resize: "none",
    outline: "none",
    color: colors.white,
    fontSize: "15px",
    marginTop: "16px",
    "&::placeholder": {
      color: colors.inputPlaceholderColor,
    },
  },
  padding: {
    paddingTop: "15px",
    paddingLeft: "24px",
    paddingRight: "24px",
    "@media(max-width: 350px)": {
      paddingLeft: "7px",
      paddingRight: "7px",
    },
  },
}));

interface Props {
  setOpen(v: boolean): void;
  setDisplayedData?: Dispatch<SetStateAction<FeedListData>>;
  editPostItem?: FeedListItem;
  mutate?: KeyedMutator<FeedListItem>;
  handleOpenDeleteDialog?(): void;
  isDeletingPost?: boolean;
}

const CreatePostPage: React.FC<Props> = (props: Props) => {
  const {
    setOpen,
    setDisplayedData,
    editPostItem,
    mutate,
    handleOpenDeleteDialog,
    isDeletingPost,
  } = props;

  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClearSearchParams = () => {
    searchParams.delete("fullScreen");
    setSearchParams(searchParams);
  };

  const {
    state: { garden, category },
  } = useAppContext();

  const { requestFn, isLoading } = useApiRequest(
    editPostItem
      ? (data) => postsApi.updatePost(data, editPostItem._id)
      : (data) => postsApi.createPost(data)
  );

  const titleName =
    category === "oslo" ? texts.feed.osloArea : texts.feed.askTheExpert;

  const [model, setModel] = useState<CreatePostParams>({
    picture: [],
    text: "",
    garden: garden?.id as string,
    category,
  });
  const [isError, setIsError] = useState(false);

  const handleModelChange = (
    key: string,
    value: string | CreatePostPictureParams
  ) => {
    setModel((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    if (isError) {
      setIsError(false);
    }

    await requestFn({
      args: model,
      successMessage: editPostItem
        ? texts.localizations.postWasUpdated
        : texts.localizations.postWasCreated,
    });
    socket.emit({
      event: "POST::CREATED",
      payload: garden?.id || category,
    });
    if (setDisplayedData) {
      setDisplayedData({});
    }
    setOpen(false);
    handleClearSearchParams();
    if (mutate) {
      mutate();
    }
  };

  const handleRemoveImageFromList = (image: string) => {
    setModel((prev) => ({
      ...prev,
      picture: model.picture.filter((item) => item !== image),
    }));
  };

  const handleCloseCreatePostDrawer = () => {
    handleClearSearchParams();
    setOpen(false);
  };

  useEffect(() => {
    if (editPostItem) {
      setModel({
        category,
        picture: editPostItem.picture,
        text: editPostItem.text,
        garden: garden?.id,
      });
    }
  }, [editPostItem, category, garden]);

  return (
    <Grid sx={{ minHeight: "100vh", position: "relative" }}>
      <Box
        py={5}
        sx={{
          background: colors.primary,
          position: "relative",
          minHeight: "100vh",
        }}
      >
        <Grid
          container
          direction="column"
          sx={{
            background: colors.primary,
            position: "relative",
          }}
        >
          <Grid item>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.padding}
            >
              <Grid item>
                <Typography
                  variant="subtitle1"
                  onClick={handleCloseCreatePostDrawer}
                >
                  {texts.localizations.cancel}
                </Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="subtitle1">
                      {editPostItem
                        ? texts.localizations.editPost
                        : texts.localizations.createPost}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">
                      {garden
                        ? `${getTrimmedText(garden.name, 20)}`
                        : titleName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  disabled={
                    isLoading ||
                    (model.text.length === 0 && model.picture.length === 0)
                  }
                  onClick={handleSubmit}
                  color="secondary"
                >
                  <Typography
                    variant="subtitle1"
                    color={
                      isLoading ||
                      (model.text.length === 0 && model.picture.length === 0)
                        ? "#A6C069"
                        : "secondary"
                    }
                  >
                    {editPostItem
                      ? texts.localizations.save
                      : texts.localizations.post}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ marginTop: "19px" }}>
            {model.picture &&
              Array.isArray(model.picture) &&
              model.picture.length > 0 && (
                <Grid item>
                  {model.picture.map((item) => (
                    <Grid sx={{ position: "relative" }}>
                      <ImageAsync src={item} alt="grow-app-post-image" />
                      <Grid
                        sx={{
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                        }}
                      >
                        <IconButton
                          onClick={() => handleRemoveImageFromList(item)}
                        >
                          <DeleteIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
            <Grid className={classes.padding} sx={{ paddingBottom: "100px" }}>
              <TextareaAutosize
                placeholder={texts.localizations.writeSomething}
                className={clsx(
                  model.picture ? classes.textAreaImage : classes.textArea
                )}
                autoFocus
                value={model.text}
                onChange={(e) => handleModelChange("text", e.target.value)}
                maxRows={24}
              />
            </Grid>
          </Grid>
          <BottomMenu
            editPostItem={editPostItem}
            setModel={setModel}
            handleOpenDeleteDialog={handleOpenDeleteDialog}
            isDeletingPost={isDeletingPost}
          />
        </Grid>
      </Box>
    </Grid>
  );
};

export default CreatePostPage;
