import { Box, IconButton, Stack } from "@mui/material";
import React from "react";
import { TrashIcon } from "../../../icons";
import colors from "../../../theme/colors";

interface Props {
  isDeletingComment?: boolean;
  handleOpenDeleteDialog(): void;
}

const CommentBottomMenu: React.FC<Props> = (props: Props) => {
  const { isDeletingComment, handleOpenDeleteDialog } = props;

  return (
    <>
      <Box
        sx={{
          background: colors.background,
          height: "52px",
          bottom: 0,
          position: "fixed",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{ height: "52px", paddingLeft: "5px" }}
        >
          <IconButton
            onClick={handleOpenDeleteDialog}
            disabled={isDeletingComment}
          >
            <TrashIcon />
          </IconButton>
        </Stack>
      </Box>
    </>
  );
};

export default CommentBottomMenu;
