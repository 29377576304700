import { Drawer, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../components/Loader";
import PostCard from "../../../components/PostCard";
import {
  addMessageHandler,
  removeMessageHandler,
} from "../../../helpers/socket";
import colors from "../../../theme/colors";
import { FeedListData, FeedListItem } from "../../../types/Feed";
import { ReactionsListData } from "../../../types/Reactions";
import { FromServerReactionUpdatedEvent } from "../../../types/ServerEvents";
import CreatePostPage from "../../CreatePostPage";
import "./styles.css";

interface Props {
  displayedData: FeedListData;
  setLastItemCreatedDate(v: string | undefined): void;
  handleRedirectTo(link: string): void;
  data: FeedListData;
  setEditPostItem(v: FeedListItem): void;
  setIsOpenPostEditDrawer(v: boolean): void;
  isEditPostDrawerOpen: boolean;
  editPostItem?: FeedListItem;
  setDisplayedData: Dispatch<SetStateAction<FeedListData>>;
}

const useStyles = makeStyles({
  root: {
    ".infinite-scroll-component__outerdiv": {
      display: " none!important",
    },
  },
  infiniteScrollContainer: {
    " > div: first-child": {
      display: "none",
    },
  },
});

type PostReaction = { [key: FeedListItem["_id"]]: ReactionsListData };

const PostsList: React.FC<Props> = (props: Props) => {
  const {
    displayedData,
    setLastItemCreatedDate,
    handleRedirectTo,
    data,
    setEditPostItem,
    setIsOpenPostEditDrawer,
    isEditPostDrawerOpen,
    editPostItem,
    setDisplayedData,
  } = props;

  const classes = useStyles();

  const [reactions, setReactions] = useState<PostReaction>({});

  useEffect(() => {
    addMessageHandler<FromServerReactionUpdatedEvent>(
      "REACTION::UPDATED",
      (payload) => {
        const { reactions, postId } = payload;

        setReactions((prev) => ({
          ...prev,
          [postId]: {
            reactions,
            myReaction: prev[postId].myReaction,
          },
        }));
      }
    );

    return () => {
      removeMessageHandler("REACTION::UPDATED");
    };
  }, [reactions]);

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={1}
        sx={{
          background: colors.background,
        }}
      >
        <div className="infinite-scroll-container">
          <InfiniteScroll
            dataLength={Object.keys(displayedData).length}
            hasMore={Object.keys(data).length === 20}
            loader={<Loader inline={false} height="100px" />}
            next={() =>
              setLastItemCreatedDate(
                Object.values(displayedData).pop()?.createdAt
              )
            }
            className={classes.root}
          >
            {Object.keys(displayedData).map((post) => {
              return (
                <Grid item key={post} sx={{ marginTop: "10px" }}>
                  <PostCard
                    userData={displayedData[post].author}
                    location={`${
                      displayedData[post].author.gardens.length > 0
                        ? displayedData[post].author.gardens[0].name
                        : ""
                    } ${
                      displayedData[post].author.address
                        ? `,${displayedData[post].author.address}`
                        : ""
                    }`}
                    postData={displayedData[post]}
                    setEditPostItem={setEditPostItem}
                    setIsOpenPostEditDrawer={setIsOpenPostEditDrawer}
                    setLastItemCreatedDate={setLastItemCreatedDate}
                    handleRedirectTo={handleRedirectTo}
                    setReactions={(reactions: ReactionsListData) =>
                      setReactions((prev) => ({
                        ...prev,
                        [post]: reactions,
                      }))
                    }
                    reactions={reactions[post]}
                  />
                </Grid>
              );
            })}
          </InfiniteScroll>
        </div>
      </Grid>
      <Drawer open={isEditPostDrawerOpen} anchor="bottom">
        <CreatePostPage
          setOpen={setIsOpenPostEditDrawer}
          setDisplayedData={setDisplayedData}
          editPostItem={editPostItem}
        />
      </Drawer>
    </>
  );
};

export default PostsList;
