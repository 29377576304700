import { v4 as uuid } from "uuid";
import parse from "html-react-parser";

const getFileWithUniqueName = (file: File): File =>
  new File([file], `${uuid()}.${file.name.split(".")[1]}`, { type: file.type });

const getTrimmedText = (d: string, length = 15): string =>
  d.length > length ? `${d.substr(0, length)}...` : d;

const convertMetresIntoDistance = (distance: number): string => {
  const km = Math.round(distance / 1000);

  return `(${km} km)`;
};

const isOneDayEvent = (start: Date, end: Date) => {
  if (
    start.getMonth() === end.getMonth() &&
    start.getDate() === end.getDate()
  ) {
    return true;
  }
  return false;
};

const convertDateToSeconds = (date: Date) => Math.floor(date.getTime() / 1000);

// eslint-disable-next-line
const checkStringForLinksRegex = new RegExp(
  "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?",
  "g"
);

const linkify = (string: string, withSpan: boolean): string => {
  const array = string.split(" ");
  array.push("");

  const parsedArrayWithLinks = array.map((item) => {
    if (checkStringForLinksRegex.test(item)) {
      return `<span id="parsed-link">${item}</span>`;
    }

    return item;
  });

  return parsedArrayWithLinks.join(" ");
};

const handleParseTextWithLineBreak = (string: string) => {
  return string.replace(/(?:\r\n|\r|\n)/g, "<br />");
};

// return string | JSX.Element | JSX.Element[]
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getParsedLinks = (string: string, withSpan = false): any => {
  const textWithLIneBreaks = handleParseTextWithLineBreak(string);
  return parse(linkify(textWithLIneBreaks, withSpan));
};

const removeHttps = (url: string): string => url.replace(/^https?:\/\//, "");

const checkforUrl = (str: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return pattern.test(str);
};

export {
  getFileWithUniqueName,
  getTrimmedText,
  convertMetresIntoDistance,
  isOneDayEvent,
  convertDateToSeconds,
  linkify,
  getParsedLinks,
  handleParseTextWithLineBreak,
  removeHttps,
  checkforUrl,
};
