import { UserDataForPostDetails } from "./Users";

export interface FeedListItem {
  _id: string;
  text: string;
  picture: string[];
  category: string;
  garden: string;
  author: UserDataForPostDetails;
  createdAt: string;
  updatedAt: string;
}

export interface FeedListData {
  [key: string]: FeedListItem;
}

export type FeedListResponse = Promise<FeedListData>;

export enum FeedCategoryEnum {
  OSLO = "oslo",
  GARDEN = "garden",
  ASK_EXPERT = "askExpert",
}

export interface CreatePostPictureParams {
  id: string;
  image: string;
}

export interface CreatePostParams {
  text: string;
  picture: string[] | [];
  garden?: string;
  category: string;
}

export interface CreatePostData {
  _id: string;
  text: string;
  picture: string;
  garden: string;
  author: string;
  createdAt: string;
  updatedAt: string;
}

export type CreatePostResponse = Promise<CreatePostData>;
