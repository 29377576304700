import { useSearchParams } from "react-router-dom";

interface Params {
  [key: string]: string;
}

const useSearchParamsHandler = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsRemoverFn = (params: string[]) => {
    return params.forEach((key) => {
      searchParams.delete(key);
      setSearchParams(searchParams);
    });
  };

  const searchParamsSetterFn = (params: Params) => {
    return Object.keys(params).forEach((key) => {
      searchParams.set(key, params[key]);
      setSearchParams(searchParams);
    });
  };

  return { searchParamsSetterFn, searchParamsRemoverFn };
};

export default useSearchParamsHandler;
