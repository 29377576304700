import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const GardenIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 3.42857C12 2.51926 11.6388 1.64719 10.9958 1.00421C10.3528 0.361224 9.48074 0 8.57143 0C7.54152 0 6.62839 0.463393 6 1.18232C5.37161 0.463393 4.45848 0 3.42857 0C2.51926 0 1.64719 0.361224 1.00421 1.00421C0.361224 1.64719 0 2.51926 0 3.42857C0 4.45848 0.463393 5.37161 1.18232 6C0.463393 6.62839 0 7.54152 0 8.57143C0 9.48074 0.361224 10.3528 1.00421 10.9958C1.64719 11.6388 2.51926 12 3.42857 12C4.45848 12 5.37161 11.5366 6 10.8177C6.62839 11.5366 7.54152 12 8.57143 12C9.48074 12 10.3528 11.6388 10.9958 10.9958C11.6388 10.3528 12 9.48074 12 8.57143C12 7.54152 11.5366 6.62839 10.8177 6C11.5366 5.37161 12 4.45848 12 3.42857ZM6 8.14286C5.57618 8.14286 5.16188 8.01718 4.80949 7.78172C4.4571 7.54626 4.18245 7.21159 4.02026 6.82004C3.85807 6.42848 3.81563 5.99762 3.89832 5.58195C3.981 5.16628 4.18509 4.78446 4.48477 4.48477C4.78446 4.18509 5.16628 3.981 5.58195 3.89832C5.99762 3.81563 6.42848 3.85807 6.82004 4.02026C7.21159 4.18245 7.54626 4.4571 7.78172 4.80949C8.01718 5.16188 8.14286 5.57618 8.14286 6C8.14286 6.56832 7.91709 7.11337 7.51523 7.51523C7.11337 7.91709 6.56832 8.14286 6 8.14286Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default GardenIcon;
