import { Avatar, CardHeader, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import texts from "../../constants/texts";
import dateToTimeAgo from "../../helpers/calculateTimeAgo";
import { getParsedLinks, getTrimmedText } from "../../helpers/common";
import useBindLinkClick from "../../hooks/useBindLinkClick";
import colors from "../../theme/colors";
import { FeedListItem } from "../../types/Feed";
import { UserDataForPostDetails } from "../../types/Users";
import ImageAsync from "../Media/ImageAsync";
import ImageSlider from "./ImageSlider";

interface Props {
  bind(): void;
  navigateToPostDetails(): void;
  userData: UserDataForPostDetails;
  postData: FeedListItem;
  location?: string;
}

const CardInfo: React.FC<Props> = (props: Props) => {
  const { bind, navigateToPostDetails, userData, postData } = props;
  const { id } = useParams();

  const titleLength = `${userData.firstName} ${
    userData.lastName
  } • ${dateToTimeAgo(new Date(postData.createdAt))}`;

  const handleCardTitle = (): string => {
    if (userData.firstName) {
      if (titleLength.length < 30) {
        return `${userData.firstName} ${userData.lastName} • ${dateToTimeAgo(
          new Date(postData.createdAt)
        )}`;
      }
      return getTrimmedText(
        `${userData.firstName} ${userData.lastName} • ${dateToTimeAgo(
          new Date(postData.createdAt)
        )}`,
        30
      );
    }
    return `${userData.displayName} • ${dateToTimeAgo(
      new Date(postData.createdAt)
    )}`;
  };

  const handleBindLinksClick = useBindLinkClick();

  useEffect(() => {
    if (id) {
      handleBindLinksClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Grid {...bind()} onClick={navigateToPostDetails}>
      <CardHeader
        avatar={
          <Avatar src={userData.picture} sx={{ width: "38px", height: "38px" }}>
            {`${postData.author.displayName[0]}`}
          </Avatar>
        }
        title={handleCardTitle()}
      />
      <Grid
        sx={{
          paddingLeft: "17px",
          paddingBottom: "12px",
          marginTop: "-10px",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            paddingRight: "10px",
            wordBreak: "break-word",
            color: "#ffffff",
          }}
        >
          {postData.text.length > 214 && !id
            ? getTrimmedText(postData.text, 214)
            : getParsedLinks(postData.text, true)}

          {postData.text.length > 214 && !id && (
            <Typography
              sx={{
                display: "inline",
                marginLeft: "15px",
                color: colors.inputPlaceholderColor,
              }}
            >
              {texts.feed.readMore}
            </Typography>
          )}
        </Typography>
      </Grid>
      {postData.picture[0] && (
        <Grid>
          {Array.isArray(postData.picture) && postData.picture.length > 1 ? (
            postData.picture.length > 0 && (
              <ImageSlider images={postData.picture} />
            )
          ) : (
            <ImageAsync src={postData.picture[0]} alt="grow-app-post-image" />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default CardInfo;
