import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import React, { useCallback, useLayoutEffect, useState } from "react";
import { setApiAuthorizationHeader } from "../api/apiClient";
import authApi from "../api/authApi";
import usersApi from "../api/usersApi";
import Loader from "../components/Loader";
import {
  deleteTokenFromLocalStorage,
  getTokenFromLocalStorage,
  setCurrentLanguage,
  setGardenCoordinates,
  setTokenToLocalStorage,
} from "../helpers/localStorage";
import { useAppContext } from "../store";
import { setCoordinates, setLanguage, setUserData } from "../store/actions";
import { LanguageEnum } from "../store/types";

interface Props {
  children: React.ReactNode;
}

const UserDataWrapper: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const { dispatch } = useAppContext();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);

  const getCurrentUser = useCallback(async () => {
    try {
      const accessToken = getTokenFromLocalStorage();
      // const accessToken = getTokenFromSessionStorage();

      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const { transferToken, language, latitude, longitude, updatedGardenId } =
        params;

      if (language) {
        dispatch(setLanguage(language as LanguageEnum));
        setCurrentLanguage(language as LanguageEnum);
      }

      if (latitude && longitude) {
        setGardenCoordinates({
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        });
        dispatch(
          setCoordinates({
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          })
        );
      }

      const checkTransferTokenResp = transferToken
        ? await authApi.checkTransferSessionToken(transferToken)
        : undefined;

      if (checkTransferTokenResp && checkTransferTokenResp.token) {
        setApiAuthorizationHeader(checkTransferTokenResp.token);
        setTokenToLocalStorage(checkTransferTokenResp.token);
        // setTokenToSessionStorage(checkTransferTokenResp.token);
        dispatch(setUserData(checkTransferTokenResp.user));

        const newURL = updatedGardenId
          ? `${window.location.href}?latitude=${latitude}&longitude=${longitude}&updatedGardenId=${updatedGardenId}`
          : `${window.location.href}?latitude=${latitude}&longitude=${longitude}`;

        window.history.pushState({ path: newURL }, "", newURL);
        return {};
      }

      if (accessToken && !checkTransferTokenResp) {
        setApiAuthorizationHeader(accessToken);
        const currentUserData = await usersApi.getCurrentUser();
        dispatch(setUserData(currentUserData));
      }
    } catch (e) {
      const error = e as AxiosError;
      if (error?.response?.status !== 404)
        enqueueSnackbar(error.response?.data?.message || error.message, {
          variant: "error",
        });
    }

    return () => {
      deleteTokenFromLocalStorage();
    };
  }, [dispatch, enqueueSnackbar]);

  useLayoutEffect(() => {
    getCurrentUser().then(() => setIsLoading(false));
  }, [getCurrentUser]);

  return isLoading ? (
    <Loader inline={false} height="100vh" />
  ) : (
    <div>{children}</div>
  );
};

export default UserDataWrapper;
