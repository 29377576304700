import {
  CreatePostParams,
  CreatePostData,
  CreatePostResponse,
  FeedListItem,
} from "../types/Feed";
import { apiClient } from "./apiClient";
import ENDPOINTS from "./endpoints";

const endpoint = ENDPOINTS.POSTS;

const createPost = (params: CreatePostParams): CreatePostResponse =>
  apiClient.post<CreatePostData>(endpoint, params).then((res) => res.data);

const updatePost = (
  params: CreatePostParams,
  postId: string
): CreatePostResponse =>
  apiClient
    .put<CreatePostData>(`${endpoint}/${postId}`, params)
    .then((res) => res.data);

const deletePost = (postId: string): Promise<void> =>
  apiClient.delete<void>(`${endpoint}/${postId}`).then((res) => res.data);

const getPostById = (postId: string): Promise<FeedListItem> =>
  apiClient.get<FeedListItem>(`${endpoint}/${postId}`).then((res) => res.data);

export default {
  createPost,
  getPostById,
  updatePost,
  deletePost,
};
