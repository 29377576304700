import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const NavigateIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.5597 10.9367L13.0631 0.44015C12.4763 -0.146717 11.5242 -0.146717 10.9369 0.44015L0.440333 10.9367C-0.146534 11.5241 -0.146534 12.4756 0.440333 13.0629L10.9369 23.5595C11.5238 24.1468 12.4758 24.1468 13.0631 23.5595L23.5597 13.0629C24.1465 12.4756 24.1465 11.5241 23.5597 10.9367ZM18.8263 11.5255L14.879 15.169C14.639 15.3907 14.2495 15.2206 14.2495 14.8934V12.3748H9.74957V15.3748C9.74957 15.582 9.58176 15.7498 9.37458 15.7498H7.8746C7.66742 15.7498 7.49961 15.582 7.49961 15.3748V11.6248C7.49961 10.7966 8.17131 10.1249 8.99958 10.1249H14.2495V7.6063C14.2495 7.27958 14.6386 7.10896 14.879 7.33067L18.8263 10.9742C18.9871 11.1228 18.9871 11.3769 18.8263 11.5255Z"
      fill="white"
    />
  </SvgIcon>
);

export default NavigateIcon;
