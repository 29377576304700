import React, { useCallback, useEffect, useState } from "react";
import { Box, keyframes } from "@mui/material";
import colors from "../../theme/colors";

interface Props {
  children: React.ReactNode;
  handleToggleRevalidateData(): void;
  isLoading: boolean;
}

const rotationAnimation = keyframes`
    100% { transform:rotate(360deg)
`;

const PullToRefreshWrapper: React.FC<Props> = ({
  children,
  handleToggleRevalidateData,
  isLoading,
}: Props) => {
  const [startPoint, setStartPoint] = useState<number>(0);
  const [pullChange, setPullChange] = useState<number>(0);
  const [isShowLoader, setIsShowLoader] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setIsShowLoader(false);
      setPullChange(0);
    }
  }, [isLoading]);

  const initLoading = useCallback(() => {
    setIsShowLoader(true);
    handleToggleRevalidateData();
  }, [handleToggleRevalidateData]);

  const pullStart = (event: TouchEvent) => {
    const { screenY } = event.targetTouches[0];
    setStartPoint(screenY);
  };

  const pull = useCallback(
    (event: TouchEvent) => {
      const touch = event.targetTouches[0];
      const { screenY } = touch;

      const pullLength = startPoint < screenY ? screenY - startPoint : 0;

      if (pullLength < 350) {
        setPullChange(pullLength);
      }
    },
    [startPoint]
  );

  const endPull = useCallback(() => {
    setStartPoint(0);

    if (pullChange < 220) {
      setPullChange(0);
    } else {
      initLoading();
    }
  }, [pullChange, initLoading]);

  useEffect(() => {
    window.addEventListener("touchstart", pullStart);
    window.addEventListener("touchmove", pull);
    window.addEventListener("touchend", endPull);
    return () => {
      window.removeEventListener("touchstart", pullStart);
      window.removeEventListener("touchmove", pull);
      window.removeEventListener("touchend", endPull);
    };
  }, [endPull, pull]);

  return (
    <Box>
      {pullChange !== 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "fixed",
            zIndex: 1,
            width: "100%",
            marginTop: pullChange / 10 || 0,
          }}
        >
          <Box
            sx={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: isShowLoader ? colors.secondary : "none",
              animation: isShowLoader
                ? `${rotationAnimation} 1s linear infinite`
                : "none",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className=""
              style={{
                transform: `rotate(${pullChange}deg)`,
                width: "30px",
                color: isShowLoader ? colors.primary : colors.secondary,
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </Box>
        </Box>
      )}
      <Box>{children}</Box>
    </Box>
  );
};

export default PullToRefreshWrapper;
