/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useState } from "react";
import { useSnackbar } from "notistack";

export type FetcherFn<Data, Args> = (
  ...args: Array<Args>
) => Data | Promise<Data>;

export interface RequestFnParams<Args = any> {
  args?: Args;
  successMessage?: string;
}

export type ResponseInterface<Data, Error, Args> = {
  data?: Data;
  error?: Error;
  isLoading?: boolean;
  requestFn(params: RequestFnParams<Args>): Promise<Data>;
};

const useApiRequest = <Data = any, Error = any, Args = any>(
  fetcher: FetcherFn<Data, Args>
): ResponseInterface<Data, Error, Args> => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<Data | undefined>(undefined);

  const requestFn = useCallback(
    async (params: RequestFnParams) => {
      try {
        setIsLoading(true);
        setError(undefined);

        const response = await fetcher(params.args);

        // if (params.successMessage) {
        //   enqueueSnackbar(params.successMessage, { variant: "success" });
        // }

        setData(response);
        setIsLoading(false);

        return response;
      } catch (e: any) {
        setError(e);
        setIsLoading(false);

        enqueueSnackbar(
          e?.response?.data?.error?.details?.executor_id ||
            e?.response?.data?.message ||
            e?.message,
          {
            variant: "error",
          }
        );

        throw e;
      }
    },
    [enqueueSnackbar, fetcher]
  );

  return {
    data,
    error,
    isLoading,
    requestFn,
  };
};

export default useApiRequest;
