import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const OsloIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 4.85714C17 3.56895 16.4883 2.33352 15.5774 1.42262C14.6665 0.511733 13.4311 0 12.1429 0C10.6838 0 9.39022 0.656473 8.5 1.67496C7.60978 0.656473 6.31618 0 4.85714 0C3.56895 0 2.33352 0.511733 1.42262 1.42262C0.511733 2.33352 0 3.56895 0 4.85714C0 6.31618 0.656473 7.60978 1.67496 8.5C0.656473 9.39022 0 10.6838 0 12.1429C0 13.4311 0.511733 14.6665 1.42262 15.5774C2.33352 16.4883 3.56895 17 4.85714 17C6.31618 17 7.60978 16.3435 8.5 15.325C9.39022 16.3435 10.6838 17 12.1429 17C13.4311 17 14.6665 16.4883 15.5774 15.5774C16.4883 14.6665 17 13.4311 17 12.1429C17 10.6838 16.3435 9.39022 15.325 8.5C16.3435 7.60978 17 6.31618 17 4.85714ZM8.5 11.5357C7.89959 11.5357 7.31267 11.3577 6.81345 11.0241C6.31423 10.6905 5.92513 10.2164 5.69537 9.66172C5.4656 9.10701 5.40548 8.49663 5.52262 7.90776C5.63975 7.31889 5.92887 6.77798 6.35343 6.35343C6.77798 5.92887 7.31889 5.63975 7.90776 5.52262C8.49663 5.40548 9.10701 5.4656 9.66172 5.69537C10.2164 5.92513 10.6905 6.31423 11.0241 6.81345C11.3577 7.31267 11.5357 7.89959 11.5357 8.5C11.5357 9.30512 11.2159 10.0773 10.6466 10.6466C10.0773 11.2159 9.30512 11.5357 8.5 11.5357Z"
      fill="white"
    />
  </SvgIcon>
);

export default OsloIcon;
