import { Avatar, Box, Drawer, Grid, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import texts from "../../constants/texts";
import CreatePostPage from "../../containers/CreatePostPage";
import { PickImageIcon } from "../../icons";
import { useAppContext } from "../../store";
import colors from "../../theme/colors";
import { FeedListData } from "../../types/Feed";

interface Props {
  handleNavigateToCreatePost(): void;
  open: boolean;
  setOpen(v: boolean): void;
  setDisplayedData: Dispatch<SetStateAction<FeedListData>>;
}

const CreatePost: React.FC<Props> = (props: Props) => {
  const { handleNavigateToCreatePost, open, setOpen, setDisplayedData } = props;

  const {
    state: { user },
  } = useAppContext();

  return (
    <>
      <Box
        sx={{ paddingLeft: "16px", paddingRight: "16px", marginBottom: "15px" }}
        py={2}
      >
        <Grid
          container
          columnSpacing={{ sm: 1 }}
          direction="row"
          alignItems="center"
          onClick={handleNavigateToCreatePost}
        >
          <Grid item xs={10}>
            <Grid
              container
              alignItems="center"
              sx={{
                background: colors.textFieldBackground,
                borderRadius: "24px 0px 0px 24px",
                height: "48px",
              }}
            >
              <Grid item sx={{ pl: "4px" }}>
                <Avatar
                  alt={`${user.firstName} ${user.lastName}`}
                  src={user.picture}
                  sx={{ width: "38px", height: "38px" }}
                />
              </Grid>
              <Grid item sx={{ pl: "10px" }}>
                <Typography variant="subtitle2" sx={{ opacity: 0.5 }}>
                  {texts.localizations.createPost}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs
            item
            sx={{
              background: colors.textFieldBackground,
              height: "48px",
              marginLeft: "1px",
              borderRadius: "0px 24px 24px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PickImageIcon />
          </Grid>
        </Grid>
      </Box>
      <Drawer
        open={open}
        anchor="bottom"
        sx={{
          "& .MuiDrawer-paper": {
            background: colors.primary,
          },
        }}
      >
        <CreatePostPage setOpen={setOpen} setDisplayedData={setDisplayedData} />
      </Drawer>
    </>
  );
};

export default CreatePost;
