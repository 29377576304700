import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  keyframes,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { KeyedMutator } from "swr";
import texts from "../constants/texts";
import colors from "../theme/colors";
import { GardensListItem } from "../types/Gardens";
import locationIcon from "../assets/locationIcon.png";
import { ArrowRightIcon } from "../icons";
import useApiRequest from "../hooks/useApiRequest";
import gardensApi from "../api/gardensApi";
import { useAppContext } from "../store";
import { appLinks } from "../routes/routes";
import { getTrimmedText, removeHttps } from "../helpers/common";
import { FeedCategoryEnum } from "../types/Feed";
import { UrlParamsEnum } from "../types/common";
import useSearchParamsHandler from "../hooks/useSearchParamsHandler";
import useBindLinkClick from "../hooks/useBindLinkClick";
import { ParsedLinksText } from "./ParsedLinksText";

interface Props {
  open: boolean | null;
  setOpen(v: boolean | null): void;
  data: GardensListItem;
  // eslint-disable-next-line
  mutate?: KeyedMutator<any>;
  setGardenInfo?(v: undefined): void;
  setIsOpenGardenInfoCard?(v: boolean): void;
  handleSelectedGardenChange?(
    gardenId: GardensListItem | undefined,
    updatedCategory: FeedCategoryEnum
  ): void;
}

const transitionEnteringScreen = keyframes`
  from {
    top: 100vh;
    height: 0px;
  }
`;

const transitionLeavingScreen = keyframes`
  from {
    top: 96px;
    height: calc(100vh - 96px);
  }
`;

const { EXTERNAL_URL, FULL_SCREEN, GARDEN_ID } = UrlParamsEnum;

const GardenDetails: React.FC<Props> = (props: Props) => {
  const {
    open,
    setOpen,
    data,
    mutate,
    setGardenInfo,
    setIsOpenGardenInfoCard,
    handleSelectedGardenChange,
  } = props;

  const {
    state: { user },
  } = useAppContext();
  const { searchParamsRemoverFn, searchParamsSetterFn } =
    useSearchParamsHandler();
  const handleBindLinksClick = useBindLinkClick();

  handleBindLinksClick();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isMyGarden = data.owner && user.id === data.owner._id;

  const handleClearSearchParams = () => {
    searchParamsRemoverFn([EXTERNAL_URL, GARDEN_ID, FULL_SCREEN]);
  };

  const handleSetSearchParams = (param: UrlParamsEnum, url: string) => {
    handleClearSearchParams();
    searchParamsSetterFn({ [FULL_SCREEN]: "true", [param]: url });
  };

  const handleCloseGardenDetails = () => {
    if (pathname === appLinks.index.link) {
      handleClearSearchParams();
    }
    setOpen(false);
    setTimeout(() => {
      setOpen(null);
    }, 300);
  };

  const isShowAnimation = () => {
    if (open === null) return null;
    if (open === true) return transitionEnteringScreen;
    return transitionLeavingScreen;
  };

  const { requestFn, isLoading } = useApiRequest(() =>
    gardensApi.subscribeToGarden(data.id)
  );
  const { requestFn: unsubscribeFromGardenFn, isLoading: isUnsubscribing } =
    useApiRequest(() => gardensApi.unsubscribeFromGarden(data.id));
  const { requestFn: deleteGardenFn, isLoading: isDeletingGarden } =
    useApiRequest(() => gardensApi.deleteGarden(data.id));

  const handleSubscribeToGarden = async () => {
    await requestFn({});
    if (mutate) {
      await mutate();
    }
    navigate(appLinks.index.link);
  };
  const handleUnsubscribeFromGardeh = async () => {
    await unsubscribeFromGardenFn({});
    if (mutate) {
      await mutate();
    }
  };
  const handleDeleteGarden = async () => {
    if (setIsOpenGardenInfoCard && setGardenInfo) {
      setIsOpenGardenInfoCard(false);
      setGardenInfo(undefined);
    }
    await deleteGardenFn({});
    if (mutate) {
      await mutate();
    }
    handleCloseGardenDetails();
  };

  const handleUnsubscribeOrDeleteGarden = () => {
    if (user.id === data.owner._id) {
      handleDeleteGarden();
    } else {
      handleUnsubscribeFromGardeh();
    }
    if (pathname === appLinks.index.link) {
      handleCloseGardenDetails();
    }
    if (handleSelectedGardenChange) {
      handleSelectedGardenChange(undefined, FeedCategoryEnum.OSLO);
    }
    handleClearSearchParams();
  };

  const isJoinedGarden = () => {
    if (data.owner) {
      if (
        user.id === data.owner._id ||
        data.subscribers.find((sub) => sub._id === user.id)
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <Box
      sx={{
        position: "fixed",
        animation: `${isShowAnimation()} 0.3s`,
        animationFillMode: "forwards",
        background: colors.background,
        width: "100%",
        bottom: 0,
        overflow: "hidden",
        borderRadius: "12px 12px 0px 0px",
        height: open ? "calc(100vh - 106px)" : "0px",
        animationDelay: "0s",
        zIndex: 222,
        overflowY: "scroll",
      }}
    >
      <Card
        sx={{
          backgroundImage: `url(${data.picture})`,
          minHeight: "422px",
          objectFit: "contain",
          backgroundSize: "cover",
          position: "relative",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          zIndex: 1,
          "&:after": {
            content: "''",
            position: "absolute",
            background: "linear-gradient(#2D9075, rgba(68, 182, 151, 0.51))",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
          },
        }}
      >
        <CardContent
          sx={{
            zIndex: 2,
            position: "relative",
            padding: "45px 24px 0px 24px",
            minHeight: "422px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid container justifyContent="space-between" sx={{ width: "100%" }}>
            <Grid
              container
              flexWrap="nowrap"
              justifyContent={isMyGarden ? "space-between" : "flex-start"}
              alignItems="center"
            >
              <Grid item onClick={() => handleCloseGardenDetails()}>
                <Typography variant="subtitle1">
                  {texts.localizations.close}
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Typography variant="subtitle1">
                  {texts.localizations.garden}
                </Typography>
              </Grid>
              {isMyGarden && (
                <Grid item>
                  <Button
                    variant="text"
                    onClick={() =>
                      handleSetSearchParams(UrlParamsEnum.GARDEN_ID, data.id)
                    }
                  >
                    {texts.localizations.edit}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h2" fontWeight={600}>
                {data.name}
              </Typography>
            </Grid>
            {data.description && (
              <Grid item>
                <ParsedLinksText text={data.description} />
              </Grid>
            )}
            {data.gardenUrl && (
              <Grid
                item
                onClick={() =>
                  handleSetSearchParams(
                    UrlParamsEnum.EXTERNAL_URL,
                    data.gardenUrl || ""
                  )
                }
              >
                <Typography
                  variant="caption"
                  sx={{
                    textDecoration: "underline",
                    color: "rgba(251, 222, 80, 1)",
                  }}
                >
                  {getTrimmedText(removeHttps(data.gardenUrl), 40)}
                </Typography>
              </Grid>
            )}
            {!isJoinedGarden() && (
              <Grid item sx={{ transition: "opacity .5s ease" }}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSubscribeToGarden}
                  disabled={isLoading || isJoinedGarden()}
                >
                  {texts.localizations.joinGarden}
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ height: "120px", marginTop: "12px" }}>
        <CardContent>
          <Typography variant="subtitle1" fontWeight={600}>
            {texts.localizations.location}
          </Typography>
          {data.location && (
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              flexWrap="nowrap"
              onClick={() =>
                handleSetSearchParams(
                  UrlParamsEnum.EXTERNAL_URL,
                  appLinks.googleMap.link(
                    data.locationCoordinates.lat,
                    data.locationCoordinates.lng
                  )
                )
              }
            >
              <Grid item>
                <Grid container spacing={2} flexWrap="nowrap">
                  <Grid item>
                    <img src={locationIcon} alt="grow-app-location-icon" />
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography>{texts.localizations.location}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2">
                          {getTrimmedText(data.location, 25)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <IconButton>
                  <ArrowRightIcon sx={{ width: "12px", height: "12px" }} />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      <Card sx={{ marginTop: "12px" }}>
        <CardContent>
          <Typography variant="subtitle1" fontWeight={600}>
            {texts.localizations.members}
          </Typography>
          <Grid container direction="column">
            {data.subscribers?.map(
              ({ _id, picture, displayName, firstName, lastName, address }) => (
                <Grid
                  item
                  key={_id}
                  sx={{
                    height: "68px",
                    borderBottom: "1px solid rgba(39, 60, 78, 0.23)",
                    display: "flex",
                    alignItems: "center",
                    opacity: 1,
                    transition: "opacity .5s ease",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item>
                      <Avatar src={picture} alt={displayName} />
                    </Grid>
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="subtitle2">{`${firstName} ${lastName}`}</Typography>
                        </Grid>
                        <Grid item>
                          {address && (
                            <Typography variant="body1">{address}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
          {isJoinedGarden() && (
            <Box sx={{ mt: 5, height: "50px", transition: "opacity .5s ease" }}>
              <Button
                variant="contained"
                fullWidth
                disabled={isUnsubscribing || isDeletingGarden}
                onClick={() => {
                  setOpen(false);
                  handleUnsubscribeOrDeleteGarden();
                }}
                sx={{
                  background: colors.headerBg,
                  color: colors.white,
                }}
              >
                {user.id === data.owner._id ? "Delete Garden" : "Leave Garden"}
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default GardenDetails;
