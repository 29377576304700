import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useSWR from "swr";
import commentsApi from "../../api/commentsApi";
import ErrorPlaceholder from "../ErrorPlaceholder";
import useApiRequest from "../../hooks/useApiRequest";
import {
  CommentCreateParams,
  CommentListItem,
  CommentsListData,
} from "../../types/Comments";
import socket, {
  addMessageHandler,
  removeMessageHandler,
} from "../../helpers/socket";
import {
  FromServerComemntRemovedEvent,
  FromServerCommentCreatedEvent,
  FromServerCommentUpdatedEvent,
} from "../../types/ServerEvents";
import Loader from "../Loader";
import { useAppContext } from "../../store";
import EditCommentDrawer from "./components/EditCommentDrawer";
import { FeedCategoryEnum } from "../../types/Feed";
import DeleteDialog from "../DeleteDialog";
import texts from "../../constants/texts";
import CreateCommentTextField from "./components/CreateCommentTextField";
import CommentsList from "./components/CommentsList";

interface Props {
  postId: string;
}

const Comments: React.FC<Props> = (props: Props) => {
  const { postId } = props;
  const {
    state: { user, category },
  } = useAppContext();

  const showCommentField =
    (category === FeedCategoryEnum.ASK_EXPERT && user.isExpert) ||
    category !== FeedCategoryEnum.ASK_EXPERT;

  const [model, setModel] = useState<CommentCreateParams>({
    text: "",
    answerOn: "",
  });
  const [displayedData, setDisplayedData] = useState<CommentsListData>({});
  const [isOpenEditCommentDrawer, setIsOpenEditCommentDrawer] = useState(false);
  const [editCommentItem, setEditCommentItem] = useState<
    CommentListItem | undefined
  >();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { data, error } = useSWR([`/comment/${postId}/list`], () =>
    commentsApi.getListOfComment(postId)
  );
  const { requestFn, isLoading } = useApiRequest((data) =>
    commentsApi.createComment(data, postId)
  );
  const { requestFn: deleteCommentFn, isLoading: isDeletingComment } =
    useApiRequest((commentId) => commentsApi.deleteComment(commentId));
  const { requestFn: updateCommentFn, isLoading: isUpdatingCommentFn } =
    useApiRequest((data) =>
      commentsApi.updateComment(data, editCommentItem?._id as string)
    );

  const handleOpenDeleteDialog = (comment?: CommentListItem) => {
    if (comment) {
      setEditCommentItem(comment);
    }
    setIsDeleteDialogOpen(true);
  };
  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleSubmit = async () => {
    const { ...rest } = await requestFn({
      args: {
        text: model.text,
        answerOn: model.answerOn || undefined,
      },
    });
    const formattedUser = { ...user, _id: user.id };

    const comment = { ...rest, author: formattedUser };

    if (!comment.answers) comment.answers = {};

    if (comment.answerOn) {
      setDisplayedData((prev) => {
        if (!comment.answerOn) return prev;
        prev[comment.answerOn].answers[comment._id] = {
          ...comment,
          author: formattedUser,
        };
        return prev;
      });
    } else {
      setDisplayedData((prev) => ({
        [comment._id]: { ...comment, author: formattedUser },
        ...prev,
      }));
    }
    setModel({
      text: "",
      answerOn: "",
    });
    socket.emit({
      event: "COMMENT::CREATED",
      payload: {
        room: postId,
        ...comment,
      },
    });
  };

  const handleOpenCreateCommentDrawer = (
    comment: CommentListItem | undefined
  ) => {
    if (category === FeedCategoryEnum.ASK_EXPERT && user.isExpert === true) {
      setIsOpenEditCommentDrawer(true);
    }
    if (category !== FeedCategoryEnum.ASK_EXPERT) {
      setIsOpenEditCommentDrawer(true);
    }
    setModel({
      text: "",
      answerOn: comment?._id || undefined,
    });
  };

  const handleOpenEditCommentDrawer = (comment: CommentListItem) => {
    setEditCommentItem(comment);
    setIsOpenEditCommentDrawer(true);
    setModel({
      text: comment.text,
      answerOn: comment.answerOn ? comment.answerOn : undefined,
    });
  };

  const handleUpdateComment = async () => {
    const { ...rest } = await updateCommentFn({
      args: {
        text: model.text,
        answerOn: model.answerOn || undefined,
      },
    });
    const formattedUser = { ...user, _id: user.id };

    const comment = { ...rest, author: formattedUser };
    if (comment.answerOn) {
      setDisplayedData((prev) => {
        if (!comment.answerOn) return prev;
        prev[comment.answerOn].answers[comment._id] = comment;
        return prev;
      });
    } else {
      setDisplayedData((prev) => {
        prev[comment._id] = { ...comment, answers: prev[comment._id].answers };
        return prev;
      });
    }
    setEditCommentItem(undefined);
    socket.emit({
      event: "COMMENT::UPDATED",
      payload: {
        room: postId,
        ...comment,
      },
    });
  };

  const handleDeleteComment = async () => {
    if (editCommentItem) {
      await deleteCommentFn({
        args: editCommentItem._id,
        successMessage: texts.comments.commentDeletedSuccessfully,
      });

      if (editCommentItem.answerOn) {
        setDisplayedData((prev) => {
          if (!editCommentItem.answerOn) return prev;
          delete prev[editCommentItem.answerOn].answers[editCommentItem._id];
          return prev;
        });
      } else {
        setDisplayedData((prev) => {
          delete prev[editCommentItem._id];
          return prev;
        });
      }
      setIsOpenEditCommentDrawer(false);
      socket.emit({
        event: "COMMENT::REMOVED",
        payload: {
          room: postId,
          ...editCommentItem,
        },
      });
      handleCloseDeleteDialog();
      setEditCommentItem(undefined);
    }
  };

  useEffect(() => {
    if (data) {
      setDisplayedData((prev) => ({ ...prev, ...data }));
    }
  }, [data]);

  useEffect(() => {
    socket.emit({
      event: "ROOM::JOIN",
      payload: postId,
    });
    return () => {
      socket.emit({ event: "ROOM::LEAVE", payload: postId });
    };
  }, [postId]);

  useEffect(() => {
    addMessageHandler<FromServerCommentCreatedEvent>(
      "COMMENT::CREATED",
      (payload) => {
        const { answerOn } = payload;
        if (!answerOn) {
          return setDisplayedData((prev) => ({
            [payload._id]: payload,
            ...prev,
          }));
        }
        return setDisplayedData((prev) => ({
          ...prev,
          [answerOn]: {
            ...prev[answerOn],
            answers: {
              ...prev[answerOn].answers,
              [payload._id]: payload,
            },
          },
        }));
      }
    );
    addMessageHandler<FromServerCommentUpdatedEvent>(
      "COMMENT::UPDATED",
      (payload) => {
        const { answerOn } = payload;
        if (!answerOn) {
          return setDisplayedData((prev) => ({
            ...prev,
            [payload._id]: { ...payload, answers: prev[payload._id].answers },
          }));
        }
        return setDisplayedData((prev) => ({
          ...prev,
          [answerOn]: {
            ...prev[answerOn],
            answers: {
              ...prev[answerOn].answers,
              [payload._id]: payload,
            },
          },
        }));
      }
    );
    addMessageHandler<FromServerComemntRemovedEvent>(
      "COMMENT::REMOVED",
      (payload) => {
        const { answerOn } = payload;
        if (!answerOn) {
          return setDisplayedData((prev) => {
            delete prev[payload._id];
            return { ...prev };
          });
        }
        return setDisplayedData((prev) => {
          if (!answerOn) return prev;
          delete prev[answerOn].answers[payload._id];
          return { ...prev };
        });
      }
    );
    return () => {
      removeMessageHandler("COMMENT::CREATED");
      removeMessageHandler("COMMENT::UPDATED");
      removeMessageHandler("COMMENT::REMOVED");
    };
  }, []);

  if (error) {
    return <ErrorPlaceholder />;
  }

  return (
    <Box sx={{ padding: "13px 16px 0px 16px", marginTop: "12px" }}>
      <>
        {showCommentField && (
          <CreateCommentTextField
            handleOpenCreateCommentDrawer={handleOpenCreateCommentDrawer}
          />
        )}
        <Typography
          sx={{ paddingTop: showCommentField ? "13px" : "0px" }}
          variant="subtitle2"
        >
          {texts.localizations.comments}(
          {data ? Object.keys(displayedData).length : 0})
        </Typography>
        <Grid
          container
          direction="column"
          spacing={1}
          sx={{ marginTop: "16px", paddingBottom: "25px" }}
        >
          {!displayedData ? (
            <Loader inline={false} height="50vh" />
          ) : (
            <CommentsList
              displayedData={displayedData}
              handleOpenCreateCommentDrawer={handleOpenCreateCommentDrawer}
              handleOpenDeleteDialog={handleOpenDeleteDialog}
              handleOpenEditCommentDrawer={handleOpenEditCommentDrawer}
            />
          )}
        </Grid>
        <EditCommentDrawer
          open={isOpenEditCommentDrawer}
          setOpen={setIsOpenEditCommentDrawer}
          editItem={editCommentItem}
          setEditItem={setEditCommentItem}
          onSubmit={editCommentItem ? handleUpdateComment : handleSubmit}
          model={model}
          setModel={setModel}
          isLoading={isLoading}
          isDeletingComment={isDeletingComment}
          isUpdatingComment={isUpdatingCommentFn}
          handleOpenDeleteDialog={handleOpenDeleteDialog}
        />
        <DeleteDialog
          dialogTitle={texts.comments.areYouSureYouWantDeleteThisComment}
          handleDelete={handleDeleteComment}
          open={isDeleteDialogOpen}
          setOpen={setIsDeleteDialogOpen}
          isLoading={isDeletingComment}
        />
      </>
    </Box>
  );
};

export default Comments;
