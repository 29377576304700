import {
  GardensSubscribedListResponse,
  GardensSubscribedListData,
  GardensListResponse,
  GardensListData,
  ImportedGardensListResponse,
  ImportedGardensListData,
} from "../types/Gardens";
import { ApiPaginationParams } from "../types/common";
import ENDPOINTS from "./endpoints";
import { apiClient } from "./apiClient";

const endpoint = ENDPOINTS.GARDENS;

const getGardensList = (params: ApiPaginationParams): GardensListResponse =>
  apiClient
    .get<GardensListData>(`${endpoint}/list`, { params })
    .then((res) => res.data);

const getGardensSubscribedList = (): GardensSubscribedListResponse =>
  apiClient
    .get<GardensSubscribedListData>(`${endpoint}/subscribed`)
    .then((res) => res.data);

const subscribeToGarden = (gardenId: string): Promise<void> =>
  apiClient
    .patch<void>(`${endpoint}/${gardenId}/subscribe`)
    .then((res) => res.data);

const unsubscribeFromGarden = (gardenId: string): Promise<void> =>
  apiClient
    .patch<void>(`${endpoint}/${gardenId}/unsubscribe`)
    .then((res) => res.data);

const deleteGarden = (gardenId: string): Promise<void> =>
  apiClient.delete(`gardens/${gardenId}`).then((res) => res.data);

const getExistingGardensList = (
  params: ApiPaginationParams
): ImportedGardensListResponse =>
  apiClient
    .get<ImportedGardensListData>(`garden/public`, { params })
    .then((res) => res.data);

export default {
  getGardensList,
  getGardensSubscribedList,
  subscribeToGarden,
  unsubscribeFromGarden,
  deleteGarden,
  getExistingGardensList,
};
