import React from "react";
import { Box, Grid, IconButton } from "@mui/material";
import {
  FaceBookIcon,
  InstagramIcon,
  TwitterIcon,
  WebIcon,
} from "../../../icons";
import colors from "../../../theme/colors";
import useSearchParamsHandler from "../../../hooks/useSearchParamsHandler";
import { UrlParamsEnum } from "../../../types/common";

interface Props {
  facebookLink?: string;
  instagramLink?: string;
  webLink?: string;
  twitterLink?: string;
}

interface MediaIconProps {
  onClick(): void;
  icon: React.ReactElement;
}

const MediaIcon: React.FC<MediaIconProps> = ({
  icon,
  onClick,
}: MediaIconProps) => {
  return (
    <Box sx={{ border: `1px solid ${colors.white}`, borderRadius: "50%" }}>
      <IconButton sx={{ color: colors.white }} onClick={onClick}>
        {icon}
      </IconButton>
    </Box>
  );
};

const { EXTERNAL_URL } = UrlParamsEnum;

const MediaIcons: React.FC<Props> = ({
  facebookLink,
  instagramLink,
  twitterLink,
  webLink,
}) => {
  const { searchParamsSetterFn } = useSearchParamsHandler();

  const links = [
    { link: facebookLink, icon: <FaceBookIcon /> },
    { link: instagramLink, icon: <InstagramIcon /> },
    { link: twitterLink, icon: <TwitterIcon /> },
    { link: webLink, icon: <WebIcon /> },
  ];

  const handleSetExternalUrl = (url: string) => {
    searchParamsSetterFn({ [EXTERNAL_URL]: url });
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignContent="center">
      {links.map(
        (link) =>
          link.link && (
            <Grid item>
              <MediaIcon
                icon={link.icon}
                onClick={() => handleSetExternalUrl(link.link as string)}
              />
            </Grid>
          )
      )}
    </Grid>
  );
};

export default MediaIcons;
