import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Grid } from "@mui/material";
import { useJsApiLoader } from "@react-google-maps/api";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { KeyedMutator } from "swr";
import GardenInfoCard from "../../containers/FindGardens/components/GardenInfoCard";
import { getGardenCoordinates } from "../../helpers/localStorage";
import { useAppContext } from "../../store";
import {
  GardenLocationCoordinates,
  GardensListData,
  GardensListItem,
} from "../../types/Gardens";
import ErrorPlaceholder from "../ErrorPlaceholder";
import Map from "./Map";
import gardenIcon from "../../assets/gardenPin.png";
import importedGardenIcon from "../../assets/importedGardenPng.png";
import { MapTypeEnum } from "../../types/common";

interface Props {
  data: GardensListData;
  existingGardens: GardensListItem[];
  error?: boolean;
  mutate: KeyedMutator<GardensListData>;
}

const DEFAULT_ZOOM = 14;

const MapComponent: React.FC<Props> = ({
  data,
  error,
  mutate,
  existingGardens,
}: Props) => {
  const {
    state: { gardenCoordinates },
  } = useAppContext();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
  });

  const arrOfClosestGardens: GardenLocationCoordinates[] = useMemo(
    () => [],
    []
  );
  const [isOpenGardenInfoCard, setIsOpenGardenInfoCard] = useState(false);
  const [gardenInfo, setGardenInfo] = useState<GardensListItem>();
  const [map, setMap] = useState<google.maps.Map>();
  const [mapType, setMapType] = useState<MapTypeEnum>(MapTypeEnum.ROAD_MAP);

  const gardensList = useRef(data.rows);

  const getClosestGardenCoordinate = (
    array: GardenLocationCoordinates[],
    centerCoordinate: GardenLocationCoordinates
  ) => {
    if (array.length === 0) {
      return null;
    }

    return array.reduce((prev, curr) => {
      return Math.abs(
        curr.lat - centerCoordinate.lat && curr.lng - centerCoordinate.lng
      ) <
        Math.abs(
          prev.lat - centerCoordinate.lat && prev.lng - centerCoordinate.lng
        )
        ? curr
        : prev;
    });
  };

  const findClosestGarden = useCallback(() => {
    const arrayOfCoordinates = gardensList.current.map(
      (item) => item.locationCoordinates
    );
    if (arrayOfCoordinates.length === 1) {
      return;
    }

    const res = getClosestGardenCoordinate(
      arrayOfCoordinates,
      JSON.parse(getGardenCoordinates())
    );
    if (res) {
      arrOfClosestGardens.push(res);
      gardensList.current = gardensList.current.filter(
        (item) => item.locationCoordinates.lat !== res.lat
      );
    }

    if (arrOfClosestGardens.length === 2) {
      return;
    }
    findClosestGarden();
  }, [arrOfClosestGardens]);

  useEffect(() => {
    if (data.count !== 0) {
      findClosestGarden();
    }
  }, [findClosestGarden, data.count]);

  const fitAllBounds = useCallback(
    (map: google.maps.Map) => {
      const gardensList = arrOfClosestGardens;

      const mapBounds = map.getBounds();
      const mapZoom = map.getZoom();

      if (mapBounds?.contains(gardensList[0])) {
        return;
      }

      if (mapZoom && mapZoom > 0) {
        map.setZoom(mapZoom - 1);
        fitAllBounds(map);
      }
    },
    [arrOfClosestGardens]
  );

  const handleCheckMarkers = useCallback(
    (map: google.maps.Map) => {
      google.maps.event.addListenerOnce(map, "bounds_changed", () => {
        fitAllBounds(map);
      });
    },
    [fitAllBounds]
  );

  const handleToggleMapType = () => {
    if (mapType === MapTypeEnum.SATELLITE) {
      setMapType(MapTypeEnum.ROAD_MAP);
    } else {
      setMapType(MapTypeEnum.SATELLITE);
    }
  };

  useEffect(() => {
    if (map) {
      handleCheckMarkers(map);
    }
  }, [handleCheckMarkers, map]);

  const handleSetMapZoom = (map: google.maps.Map) => {
    google.maps.event.addListenerOnce(map, "bounds_changed", () => {
      if (map.getZoom()) {
        map.setZoom(DEFAULT_ZOOM);
      }
    });
  };

  const handleSetMapCenter = (map: google.maps.Map) => {
    const urlCoordinates: GardenLocationCoordinates = {
      lat: gardenCoordinates.lat,
      lng: gardenCoordinates.lng,
    };

    const bounds = new google.maps.LatLngBounds({
      lat: urlCoordinates.lat,
      lng: urlCoordinates.lng,
    });
    map.fitBounds(bounds);
    handleSetMapZoom(map);
  };

  const onLoad = (map: google.maps.Map) => {
    handleSetMapCenter(map);

    setMap(map);
  };

  const handleOpenGardenDetailsDrawer = useCallback(
    (garden: GardensListItem) => {
      if (
        data &&
        !data.rows.includes(garden) &&
        existingGardens &&
        !existingGardens.includes(garden)
      ) {
        return;
      }
      setGardenInfo(garden);
      setIsOpenGardenInfoCard(true);
    },
    [data, existingGardens]
  );

  useEffect(() => {
    if (data && map) {
      const mergedDataAndExistingGardens = [...data.rows, ...existingGardens];
      const checkForUndefined = mergedDataAndExistingGardens.filter(
        (marker) => typeof marker.locationCoordinates === "object"
      );

      const markers = checkForUndefined.map((item) => {
        const marker = new google.maps.Marker({
          position: item.locationCoordinates,
          map,
          icon: {
            url: item.imported ? importedGardenIcon : gardenIcon,
          },
        });

        marker.addListener("click", () => {
          handleOpenGardenDetailsDrawer(item as GardensListItem);
        });
        return marker;
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const clasters = new MarkerClusterer({ map, markers });
    }
  }, [map, data, gardenInfo, existingGardens, handleOpenGardenDetailsDrawer]);

  if (error) {
    return <ErrorPlaceholder />;
  }

  return isLoaded && data ? (
    <Grid
      sx={{
        height: "calc(100vh - 88px)",
        "&: after": {
          content: "''",
          position: "absolute",
          background: isOpenGardenInfoCard ? "rgba(0, 0, 0, 0.5)" : "none",
          width: isOpenGardenInfoCard ? "100%" : 0,
          height: "100vh",
          top: 0,
          left: 0,
          opacity: isOpenGardenInfoCard ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
        },
      }}
    >
      {data && (
        <Grid>
          <Map
            onLoad={onLoad}
            map={map}
            handleSetMapCenter={handleSetMapCenter}
            handleToggleMapType={handleToggleMapType}
            mapType={mapType}
          />
        </Grid>
      )}
      {gardenInfo && mutate && (
        <GardenInfoCard
          open={isOpenGardenInfoCard}
          setOpen={setIsOpenGardenInfoCard}
          data={
            data.rows.find((garden) => garden.id === gardenInfo.id) ||
            existingGardens.find((garden) => garden.id === gardenInfo.id)
          }
          setGardenInfo={setGardenInfo}
          mutate={mutate}
        />
      )}
    </Grid>
  ) : null;
};

export default MapComponent;
