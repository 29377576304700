import { LanguageEnum } from "../../store/types";
import { getCurrentLanguage } from "../../helpers/localStorage";

const language: LanguageEnum = getCurrentLanguage() as LanguageEnum;

const informationModal = {
  description: {
    en: "There are several websites that give good advice on where you can apply for financial support for your urban garden project",
    nb: "Det er flere nettsteder som gir gode råd om hvor du kan søke om økonomisk støtte til ditt urbanehageprosjekt",
  },
  subsidy: {
    en: "Subsidy for urban agriculture",
    nb: "Tilskudd til urbant landbruk",
  },
  burgeoning: {
    en: "About burgeoning Oslo",
    nb: "Om spirende Oslo",
  },
  grantPortal: {
    en: "The grant portal",
    nb: "Tilskuddsportalen",
  },
  volunteering: {
    en: "Volunteering Norway and Lottery",
    nb: "Frivillighet Norge og Lotteri",
  },
  authority: {
    en: "The Foundation Authority",
    nb: "Stiftelsestilsynet",
  },
  organizations: {
    en: "Organizations that work",
    nb: "Organisasjoner som funker",
  },
  foundation: {
    en: "Gjensidige Foundation",
    nb: "Gjensidige Stiftelsen",
  },
};

export default {
  description: informationModal.description[language],
  subsidy: informationModal.subsidy[language],
  burgeoning: informationModal.burgeoning[language],
  grantPortal: informationModal.grantPortal[language],
  volunteering: informationModal.volunteering[language],
  authority: informationModal.authority[language],
  organizations: informationModal.organizations[language],
  foundation: informationModal.foundation[language],
};
