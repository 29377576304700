import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const RoadMapIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    {...props}
  >
    <path
      d="M0 3.71619V16.9437C0 17.3761 0.436563 17.6717 0.837986 17.5113L6.11111 15.1111V0.444458L0.768472 2.58144C0.541665 2.67215 0.347237 2.82874 0.210264 3.031C0.0732908 3.23326 5.43204e-05 3.47192 0 3.71619H0ZM7.33333 15.1111L14.6667 17.5556V2.8889L7.33333 0.444458V15.1111ZM21.162 0.488764L15.8889 2.8889V17.5556L21.2315 15.4186C21.4584 15.3279 21.6528 15.1713 21.7898 14.9691C21.9268 14.7668 22 14.5281 22 14.2838V1.05633C22 0.623972 21.5634 0.328347 21.162 0.488764Z"
      fill="white"
    />
  </SvgIcon>
);

export default RoadMapIcon;
