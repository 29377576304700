import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const FindGardensIcon: React.FC = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.8"
      d="M0 4.67687V15.4994C0 15.8531 0.357188 16.095 0.685625 15.9637L5 14V2L0.62875 3.74844C0.443181 3.82266 0.284103 3.95078 0.172034 4.11626C0.0599652 4.28175 4.4444e-05 4.47701 0 4.67687H0ZM6 14L12 16V4L6 2V14ZM17.3144 2.03625L13 4V16L17.3713 14.2516C17.5568 14.1774 17.7159 14.0493 17.828 13.8838C17.9401 13.7183 18 13.523 18 13.3231V2.50062C18 2.14688 17.6428 1.905 17.3144 2.03625V2.03625Z"
      fill="white"
    />
  </SvgIcon>
);

export default FindGardensIcon;
