import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const PickImageIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.6667 15.5555V16.1111C16.6667 17.0315 15.9205 17.7777 15 17.7777H1.66667C0.746181 17.7777 0 17.0315 0 16.1111V7.22217C0 6.30168 0.746181 5.5555 1.66667 5.5555H2.22222V7.22217H1.875C1.81975 7.22217 1.76676 7.24412 1.72769 7.28319C1.68862 7.32226 1.66667 7.37525 1.66667 7.4305V15.9027C1.66667 15.958 1.68862 16.011 1.72769 16.05C1.76676 16.0891 1.81975 16.1111 1.875 16.1111H14.7917C14.8469 16.1111 14.8999 16.0891 14.939 16.05C14.9781 16.011 15 15.958 15 15.9027V15.5555H16.6667ZM18.125 3.88883H5.20833C5.15308 3.88883 5.10009 3.91078 5.06102 3.94985C5.02195 3.98892 5 4.04191 5 4.09717V12.5694C5 12.6246 5.02195 12.6776 5.06102 12.7167C5.10009 12.7558 5.15308 12.7777 5.20833 12.7777H18.125C18.1803 12.7777 18.2332 12.7558 18.2723 12.7167C18.3114 12.6776 18.3333 12.6246 18.3333 12.5694V4.09717C18.3333 4.04191 18.3114 3.98892 18.2723 3.94985C18.2332 3.91078 18.1803 3.88883 18.125 3.88883ZM18.3333 2.22217C19.2538 2.22217 20 2.96835 20 3.88883V12.7777C20 13.6982 19.2538 14.4444 18.3333 14.4444H5C4.07951 14.4444 3.33333 13.6982 3.33333 12.7777V3.88883C3.33333 2.96835 4.07951 2.22217 5 2.22217H18.3333ZM9.16667 6.11106C9.16667 6.87811 8.54483 7.49995 7.77778 7.49995C7.01073 7.49995 6.38889 6.87811 6.38889 6.11106C6.38889 5.34401 7.01073 4.72217 7.77778 4.72217C8.54483 4.72217 9.16667 5.34401 9.16667 6.11106ZM6.66667 9.44439L8.03872 8.07234C8.20142 7.90963 8.46524 7.90963 8.62799 8.07234L10 9.44439L13.5943 5.85012C13.757 5.68741 14.0208 5.68741 14.1835 5.85012L16.6667 8.33328V11.1111H6.66667V9.44439Z"
      fill="white"
      fillOpacity="0.8"
    />
  </SvgIcon>
);

export default PickImageIcon;
