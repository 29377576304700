import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const ArrowBackIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.8914 17.9968L9.9526 18.9356C9.55509 19.3331 8.9123 19.3331 8.51902 18.9356L0.298134 10.719C-0.099378 10.3214 -0.099378 9.67866 0.298134 9.28538L8.51902 1.06449C8.91653 0.666979 9.55932 0.666979 9.9526 1.06449L10.8914 2.0033C11.2931 2.40504 11.2847 3.06051 10.8745 3.45379L5.77873 8.30852H17.9324C18.4949 8.30852 18.9474 8.761 18.9474 9.32344V10.6767C18.9474 11.2391 18.4949 11.6916 17.9324 11.6916H5.77873L10.8745 16.5463C11.2889 16.9396 11.2974 17.5951 10.8914 17.9968Z"
      fill="url(#paint0_linear_1195_4632)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1195_4632"
        x1="18.9474"
        y1="19.2338"
        x2="18.9474"
        y2="0.766357"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBDE50" />
        <stop offset="1" stopColor="#FFE773" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default ArrowBackIcon;
