const fullScreenParam = "?fullScreen=true";

const appLinks = {
  index: {
    name: "Home",
    link: "/",
  },
  postDetails: {
    name: "Post Details",
    link: "/post/:id",
    asLink: (id: string): string => `/post/${id}${fullScreenParam}`,
  },
  createPost: {
    name: "Create Post",
    link: "/post-create",
  },
  findGardens: {
    name: "Find Gardens",
    link: "/find-gardens",
    asLink: `/find-gardens${fullScreenParam}`,
  },
  findGardensSearch: {
    name: "Search Gardens",
    link: "/find-gardens/search",
    asLink: `/find-gardens/search${fullScreenParam}`,
  },
  googleMap: {
    name: "Google Map",
    link: (lat: number, lng: number): string =>
      // `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
      // `https://dyrk.app/?showMaps=true&latitude=${lat}&longitude=${lng}`,
      `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
  },
  selectGarden: {
    name: "Select Garden",
    link: "/select-garden",
  },
  events: {
    name: "Events",
    link: "/events",
  },
};

export { appLinks };
