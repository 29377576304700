import {
  CheckTransferTokenData,
  CheckTransferTokenResponse,
} from "../types/Auth";
import { apiClient } from "./apiClient";
import ENDPOINTS from "./endpoints";

const usersEndpoint = ENDPOINTS.USERS;

const checkTransferSessionToken = (token: string): CheckTransferTokenResponse =>
  apiClient
    .get<CheckTransferTokenData>(`${usersEndpoint}/transfer-session/${token}`)
    .then((res) => res.data);

export default {
  checkTransferSessionToken,
};
