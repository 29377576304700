import {
  ClientToServerEvent,
  ServerToClientEvent,
  SocketError,
  SocketEventHandler,
} from "../types/Socket";

const connect = () => {
  const socket = new WebSocket("wss://api.dyrk.app");
  // todo - check if socket reconnects

  socket.onmessage = (e: MessageEvent<string>) => {
    if (socket.readyState !== socket.OPEN) return;
    const data = JSON.parse(e.data) as ServerToClientEvent | SocketError;

    if ("name" in data) {
      // eslint-disable-next-line
      console.log("ERROR", data);
      return;
    }

    const { event, payload } = data;

    const handler = handlerSocketResponse[event];
    if (handler) {
      handler(payload);
    }

    socket.onopen = () => {
      if (!emitQueue.length) return;
      emitQueue.forEach(emit);
    };
  };

  return socket;
};

let socket: WebSocket;
socket = connect();

const emitQueue: ClientToServerEvent[] = [];

socket.onclose = () => {
  socket = connect();
};

const emit = (data: ClientToServerEvent) => {
  if (socket.readyState === socket.CONNECTING) {
    emitQueue.push(data);
  }
  if (socket.readyState !== socket.OPEN) return;
  socket.send(JSON.stringify(data));
};

type HandleKeys = ServerToClientEvent["event"];

const handlerSocketResponse: {
  // eslint-disable-next-line
  [key in HandleKeys]?: SocketEventHandler<any>;
} = {};

export function addMessageHandler<T extends ServerToClientEvent>(
  key: T["event"],
  callbackFn: SocketEventHandler<T["payload"]>
) {
  handlerSocketResponse[key] = callbackFn;
}

export function removeMessageHandler(name: HandleKeys) {
  delete handlerSocketResponse[name];
}

export default {
  socket,
  emit,
};
