import {
  FeedListResponse,
  FeedListData,
  FeedCategoryEnum,
} from "../types/Feed";
import { apiClient } from "./apiClient";
import ENDPOINTS from "./endpoints";

const endpoint = ENDPOINTS.POSTS;

const getListOfPosts = (
  gardenId: string | undefined,
  category: FeedCategoryEnum,
  createdAt?: string
): FeedListResponse =>
  apiClient
    .get<FeedListData>(
      `${endpoint}?limit=20&${
        gardenId ? `garden=${gardenId}` : ""
      }&category=${category}${
        createdAt ? `&createdAt=${createdAt}` : ""
      }&sort=-createdAt`
    )
    .then((res) => res.data);

export default { getListOfPosts };
