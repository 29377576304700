import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Grid, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import FindGardens from "..";
import texts from "../../../constants/texts";
import { ArrowBackIcon, SearchIcon } from "../../../icons";
import { appLinks } from "../../../routes/routes";
import "../styles.css";

const FindGardensContainer: React.FC = () => {
  const navigate = useNavigate();
  const [isShowMap, setIsShowMap] = useState(false);
  const [searchParams] = useSearchParams();
  const [isShowArrowBackButton, setIsShowArrowBackIcon] = useState(true);

  useEffect(() => {
    const param = searchParams.get("transferToken");
    if (param) {
      setIsShowArrowBackIcon(false);
    }
  }, [searchParams]);

  const handleNavigateToFeed = () => {
    navigate(appLinks.index.link);
  };

  useEffect(() => {
    setTimeout(() => setIsShowMap(true), 500);
  }, []);

  return (
    <motion.div
      initial={{ transform: "translateX(100%)" }}
      animate={{ transform: "translateX(0%)" }}
      exit={{ transform: "translateX(100%)" }}
    >
      <Grid sx={{ width: "100%", zIndex: 2, top: 0 }}>
        <Grid
          container
          sx={{
            height: "98px",
            padding: "35px 23px 0px 23px",
          }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item onClick={handleNavigateToFeed}>
            {isShowArrowBackButton && (
              <ArrowBackIcon sx={{ width: "19px", height: "19px" }} />
            )}
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {texts.localizations.findGardens}
            </Typography>
          </Grid>
          <Grid
            item
            onClick={() => navigate(appLinks.findGardensSearch.asLink)}
          >
            <SearchIcon sx={{ width: "18px", height: "18px" }} />
          </Grid>
        </Grid>
        {isShowMap && <FindGardens />}
      </Grid>
    </motion.div>
  );
};

export default FindGardensContainer;
