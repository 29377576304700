import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const AddReactionIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.45455 6.3877C4.13944 6.3877 1.5 9.08973 1.5 12.4439H2.5C2.5 9.62452 4.70905 7.3877 7.45455 7.3877V6.3877ZM1.5 12.4439C1.5 15.7981 4.13944 18.5001 7.45455 18.5001V17.5001C4.70905 17.5001 2.5 15.2633 2.5 12.4439H1.5ZM7.45455 18.5001C10.7697 18.5001 13.4091 15.7981 13.4091 12.4439H12.4091C12.4091 15.2633 10.2 17.5001 7.45455 17.5001V18.5001ZM13.4091 12.4439C13.4091 9.08973 10.7697 6.3877 7.45455 6.3877V7.3877C10.2 7.3877 12.4091 9.62452 12.4091 12.4439H13.4091ZM4.61786 14.5134C5.2495 15.4455 6.25069 16.0306 7.45603 16.0306V15.0306C6.60197 15.0306 5.90255 14.6266 5.44571 13.9524L4.61786 14.5134ZM7.45603 16.0306C8.66136 16.0306 9.66255 15.4455 10.2942 14.5134L9.46634 13.9524C9.0095 14.6266 8.31008 15.0306 7.45603 15.0306V16.0306Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.48165 10.5799C6.48165 11.0506 6.08911 11.4319 5.60446 11.4319C5.11981 11.4319 4.72726 11.0506 4.72726 10.5799C4.72726 10.1091 5.11981 9.72778 5.60446 9.72778C6.08911 9.72778 6.48165 10.1091 6.48165 10.5799Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.118 10.5799C10.118 11.0506 9.72549 11.4319 9.24084 11.4319C8.75619 11.4319 8.36365 11.0506 8.36365 10.5799C8.36365 10.1091 8.75619 9.72778 9.24084 9.72778C9.72549 9.72778 10.118 10.1091 10.118 10.5799Z"
      fill="white"
    />
    <path
      d="M17.69 10.1945H14.7273V8.9204H17.69V6H19.0299V8.9204H22V10.1945H19.0299V13.1006H17.69V10.1945Z"
      fill="white"
    />
  </SvgIcon>
);

export default AddReactionIcon;
