import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { motion } from "framer-motion";
import postsApi from "../../../api/postsApi";
import ErrorPlaceholder from "../../../components/ErrorPlaceholder";
import Loader from "../../../components/Loader";
import PostCard from "../../../components/PostCard";
import texts from "../../../constants/texts";
import socket from "../../../helpers/socket";
import { ArrowBackIcon, DeleteIcon } from "../../../icons";
import { appLinks } from "../../../routes/routes";
import { useAppContext } from "../../../store";
import colors from "../../../theme/colors";
import Comments from "../../../components/Comments";
import CreatePostPage from "../../CreatePostPage";
import DeleteDialog from "../../../components/DeleteDialog";
import useApiRequest from "../../../hooks/useApiRequest";

const PostDetails: React.FC = () => {
  const {
    state: { garden, user },
  } = useAppContext();
  const { id } = useParams();
  const navigate = useNavigate();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const isUserOwner = useMemo(
    () => garden?.owner._id === user.id,
    [garden, user]
  );

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const { data, error, mutate } = useSWR([`/posts/${garden}/${id}`, id], () =>
    postsApi.getPostById(id as string)
  );
  const { requestFn: deletePostFn, isLoading: isDeletingPost } = useApiRequest(
    () => postsApi.deletePost(id as string)
  );

  const handleDeletePost = async () => {
    await deletePostFn({
      successMessage: texts.createPost.postDeletedSuccessfully,
    });

    setIsDeleteDialogOpen(false);
    navigate(appLinks.index.link);
  };

  const handleRedirectToFeed = () => {
    socket.emit({ event: "ROOM::LEAVE", payload: id as string });
    navigate(appLinks.index.link);
  };

  if (error) {
    return <ErrorPlaceholder />;
  }

  return !data ? (
    <Loader inline={false} height="100vh" />
  ) : (
    <>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: "0%" }}
        exit={{ x: "100%" }}
      >
        <Box>
          <Grid
            container
            sx={{
              height: "98px",
              background: colors.background,
              padding: "35px 24px 0px 24px",
              marginBottom: "30px",
              zIndex: 10,
            }}
            alignItems="center"
            justifyContent="space-between"
            position="relative"
          >
            <Grid item>
              <IconButton onClick={handleRedirectToFeed}>
                <ArrowBackIcon sx={{ width: "19px", height: "19px" }} />
              </IconButton>
            </Grid>
            {id && (
              <Grid
                item
                sx={{
                  flex: "0 1 auto",
                  transform: "translateX(-50%)",
                  left: "50%",
                  position: "absolute",
                }}
              >
                <Typography variant="subtitle1">
                  {texts.localizations.post}
                </Typography>
              </Grid>
            )}
            {user.id === data.author._id && (
              <Grid item>
                <Button variant="text" onClick={() => setOpen(true)}>
                  {texts.localizations.edit}
                </Button>
              </Grid>
            )}
            {user.id !== data.author._id && isUserOwner && (
              <IconButton onClick={handleOpenDeleteDialog}>
                <DeleteIcon sx={{ color: colors.secondary }} />
              </IconButton>
            )}
          </Grid>
          <Grid sx={{ borderBottom: `12px solid ${colors.background}` }}>
            <PostCard
              userData={data.author}
              location={`${data.author.gardens[0]?.location || ""}, ${
                data.author.address
              }`}
              postData={data}
            />
          </Grid>
          <Comments postId={data._id} />
        </Box>
      </motion.div>
      <Drawer open={open} anchor="bottom">
        <CreatePostPage
          setOpen={setOpen}
          editPostItem={data}
          mutate={mutate}
          handleOpenDeleteDialog={handleOpenDeleteDialog}
          isDeletingPost={isDeletingPost}
        />
      </Drawer>
      <DeleteDialog
        dialogTitle={texts.createPost.areYouSureYouWantToDeleteThisPost}
        handleDelete={handleDeletePost}
        open={isDeleteDialogOpen}
        setOpen={setIsDeleteDialogOpen}
        isLoading={isDeletingPost}
      />
    </>
  );
};

export default PostDetails;
